export const state = {
    activeItems: [],
}

export const getters = {};

export const mutations = {

    setActiveItem(state, item){
        let items = [...state.activeItems];


        if(item.index === 0){
            state.activeItems = [item.value]
            return;
        }
        // replace or push to array.
        if(items.length > item.index + 1){
            items.push(item.value);
        }else{
            items[item.index] = item.value;
        }

        // re-assignment here to trigger an update on component instead of direct editing!
        state.activeItems = items;
    }
}