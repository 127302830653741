<template>
  <b-tabs content-class="mt-1" nav-class="nav-tabs nav-bordered">
    <b-tab title="Διατροφικό πλάνο">
      <table v-if="!loadingDietPlan" id="dietPlan-table" class="table table-responsive table-borderless" style="table-layout: fixed; ">
        <tbody>
        <tr v-for="(row, idx1) in dpMeals" :key="idx1">

          <th class="table-meal-day-name">

            {{dayNames[idx1]}}</th>

          <td v-for="(idx2) in getValidMealsOrder(dietPlan)" :key="idx2" :id="'meal-'+idx1 +''+ idx2" class="table-meal">
            <div class="row-header day">

              {{getMealName(dietPlan, dpMeals[idx1][idx2].type)}}
            </div>
            <div class="row-content">
              <div v-for="mealfood in dpMeals[idx1][idx2].foods" :key="mealfood.id">
                <div class="table-meal-food" :class="'meal-category-' + mealfood.category">
                  <!--                      {{mealfood}}-->
                  {{formatName(mealfood)}}:  {{ formatFoodUnits(mealfood)}}
                </div>
              </div>

              <div v-if="dpMeals[idx1][idx2].notes && !dietPlan.isTextOnly" class="meal-category-notes">

                <strong>Σημειώσεις:</strong> {{dpMeals[idx1][idx2].notes}}
              </div>
              <div v-if="dpMeals[idx1][idx2].notes && dietPlan.isTextOnly" class="meal-category-text-only-notes" v-html="dpMeals[idx1][idx2].notes"/>

            </div>

          </td>
        </tr>
        </tbody>
      </table>
    </b-tab>
    <b-tab title="Ανάλυση πλάνου">
      <b-overlay :show="loadingDietPlan" rounded="sm">
        <b-tabs>
          <b-tab title="Μακροθρεπτικά">
            <div v-if="!loadingDietPlan">
              <div class="row">.
                <div class="col-md-12">
                  <div class="row mb-3 p-2">
                    <div class="col-lg-3 col-md-6 p-1" >
                      <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                        <h4>{{getAverage('calories')}} kcal</h4>
                        <span class="text-muted">Μέσος όρος ενέργειας <br>(μόνο ημέρες με τρόφιμα)</span>
                      </div>

                    </div>
                    <div class="col-lg-3 col-md-6 p-1" >
                      <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                        <h4>{{getAverage('proteins')}} g</h4>
                        <span class="text-muted">Μέσος όρος πρωτεϊνών  <br>(μόνο ημέρες με τρόφιμα)</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 p-1">
                      <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                        <h4>{{getAverage('carbs')}} g</h4>
                        <span class="text-muted">Μέσος όρος υδατανθράκων  <br>(μόνο ημέρες με τρόφιμα)</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 p-1">
                      <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                        <h4>{{getAverage('fats')}} g</h4>
                        <span class="text-muted">Μέσος όρος λιπών  <br>(μόνο ημέρες με τρόφιμα)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <h5 style="min-height: 35px;"> Ενέργεια</h5>
                  <apexchart height="300px" type="line" :options="getOptions('CALORIES')" :series="getSeries('CALORIES')"></apexchart>
                </div>
                <div class="col-md-4">
                  <h5><img src="@assets/images/icons/icon-protein-dark.png" alt="icon-apple" width="45px"/>Υδατάνθρακες  / <img src="@assets/images/icons/icon-amulouxa-dark.png" alt="icon-apple" width="45px"/>Πρωτεϊνες</h5>
                  <apexchart height="300px" type="line" :options="getOptions('CARBS-PROTEINS')" :series="getSeries('CARBS-PROTEINS')"></apexchart>
                </div>
                <div class="col-md-4">
                  <h5>Στατιστικά τροφίμων</h5>
                  <apexchart height="300px" type="pie" :options="pieOptions" :series="getSeries('FOOD-PIE')"></apexchart>

                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <h5><img src="@assets/images/icons/icon-protein-dark.png" alt="icon-apple" width="45px"/>Πρωτεϊνες</h5>
                  <apexchart height="300px" type="line" :options="getOptions('PROTEINS')" :series="getSeries('PROTEINS')"></apexchart>
                </div>
                <div class="col-md-4">
                  <h5><img src="@assets/images/icons/icon-amulouxa-dark.png" alt="icon-apple" width="45px"/> Υδατάνθρακες</h5>
                  <apexchart height="300px" type="line" :options="getOptions('CARBS')" :series="getSeries('CARBS')"></apexchart>
                </div>
                <div class="col-md-4">
                  <h5><img src="@assets/images/icons/icon-fats-dark.png" alt="icon-apple" width="45px"/> Λίπη</h5>
                  <apexchart height="300px" type="line" :options="getOptions('FATS')" :series="getSeries('FATS')"></apexchart>
                </div>
              </div>

            </div>
          </b-tab>
          <b-tab title="Μικροθρεπτικά">
            <div v-if="!loadingDietPlan" class="tabs">
             <b-tabs justified nav-class="nav-tabs nav-bordered" vertical>

                <b-tab v-for="(day,index) in dayNames" :key="day" :title="day">

                  <div class="nutrient-wrapper pl-md-5">
                    <table class="micronutrients-table">
                      <tr>
                        <th>Μικροθρεπτικό</th>
                        <th>Ποσότητα</th>
                      </tr>
                      <tr v-if="dpMeals[index].totalStats.microNutrients && dpMeals[index].totalStats.microNutrients[nutrient]"
                          v-for="(nutrient,index2) of microNutrientFields" :key="index + '-'+index2" class="nutrient-item">
                        <td>{{microNutrientTranslation[nutrient]}}: </td>
                        <td >{{+dpMeals[index].totalStats.microNutrients[nutrient].toFixed(2)}}</td>


                      </tr>
                    </table>
                  </div>




                </b-tab>
              </b-tabs>

            </div>

          </b-tab>



        </b-tabs>

      </b-overlay>
    </b-tab>

    <b-tab title="Συζήτηση / σχόλια">
      <cloud-listing-comments :listing-id="listingId"></cloud-listing-comments>
    </b-tab>
  </b-tabs>

</template>


<script>

import Vue from "vue";
import CloudListingComments from "@components/cloud/preview/CloudListingComments";
import DietPlanMixin from "@src/mixins/DietPlanMixin";
export default{

  components:{CloudListingComments},
  props:{
    dietPlan: Object,
    listingId: String
  },
  mixins: [DietPlanMixin],
  computed:{

  },
  data(){
    return{
      dpMeals: [[], [], [], [], [], [], []],
      loadingDietPlan: true,
      foodStatistics: null,
      pieOptions:{
        chart: {
          width: 250,
          type: 'pie',
        },
        labels: ['Φρέσκες', 'Αμυλούχες','Λίπη', 'Ζωϊκές', 'Σύνθετα τρόφιμα', 'Ποτά / Γενικά'],
        colors:['#1abc9c', '#3155A6','#f7b84b','#f1556c', '#d3d3d3', '#0891b2'],
        tooltip:{
          y: {
            formatter: (value) => { return value + ' τρόφιμα' },
          },
        },
        legend:{
          position: 'bottom'
        },
        stroke:{
          width:0
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 220
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      diagrams: {
        stroke:{
          width: 2,
          curve: 'smooth'
        }
      },
    }
  },
  mounted(){
    if(!this.dietPlan.dietPlanMealOptions){
      this.dietPlan.dietPlanMealOptions = {
        totalMeals: 5,
        mealsOrder: [0,1,2,3,4,5,6],
        mealNames: {0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null}
      }
    }
    this.initialDietPlanFormatting();
  },

  methods:{
    initialDietPlanFormatting(){
      if(!this.dietPlan  || !this.dietPlan.meals) return [];

      const meals = this.dietPlan.meals;

      for(let i=0;i<7;i++){
        let dayMeals = meals.filter(x => x.day === i);
        for(let j=0;j < dayMeals.length; j++){
          this.dpMeals[i].push(dayMeals.filter(x => x.type === j)[0]);
        }
      }
      this.displayAndCalculateFoods();
    },
    displayAndCalculateFoods(){
      let foodStats = [0,0,0,0,0,0];
      for(let i=0; i<7;i++){
        let dayStats = {calories: 0, carbs: 0, proteins: 0, fats: 0, microNutrients: {}};
        for(let j=0;j<this.dietPlan.dietPlanMealOptions.totalMeals;j++){
          let totalStats = {calories: 0, carbs: 0, proteins: 0, fats: 0, microNutrients: {}};
          // sort food list by ORDER first, ID second.
          if(this.dpMeals[i][j].foods) this.dpMeals[i][j].foods.sort( (a,b) => {
            const aId = a.id;
            const bId = b.id;
            const aOrder = a['mealFoods.order'] === null ? 999 : a['mealFoods.order'];
            const bOrder = b['mealFoods.order'] === null ? 999 : b['mealFoods.order'];
            if(aOrder === 999 && bOrder === 999) return aId - bId;
            return aOrder - bOrder;
          });
          for(let fd of this.dpMeals[i][j].foods){

            foodStats[fd.category-1] += 1;
            let quantity = fd['mealFoods.quantity'];
            // fd.food = JSON.parse(JSON.stringify(this.foods.find(food => food.id === fd.foodId )));
            fd.kcal *= quantity;
            fd.carbohydrates *= quantity;
            fd.proteins *=quantity;
            fd.fats *= quantity;
            fd.quantity *= quantity;

            totalStats.calories += fd.kcal;
            totalStats.carbs += fd.carbohydrates;
            totalStats.proteins += fd.proteins;
            totalStats.fats += fd.fats;
            // calculate micro-nutrients
            if(fd.list !== 0){
              for(let nutrient of this.microNutrientFields){
                if(!fd.microNutrients[nutrient]) continue;
                if(!totalStats.microNutrients[nutrient] ) totalStats.microNutrients[nutrient] = 0;
                totalStats.microNutrients[nutrient] += quantity * parseFloat(fd.microNutrients[nutrient]);
              }
            }
          }

          // add sums to dayStats!
          dayStats.calories += totalStats.calories;
          dayStats.carbs += totalStats.carbs;
          dayStats.proteins += totalStats.proteins;
          dayStats.fats += totalStats.fats;
          for(let nutrient of this.microNutrientFields){
            if(!dayStats.microNutrients[nutrient] ) dayStats.microNutrients[nutrient] = 0;
            dayStats.microNutrients[nutrient] += totalStats.microNutrients[nutrient];
          }
          // set meal
          Vue.set(this.dpMeals[i][j], 'statistics', totalStats);
        }

        // set day!
        Vue.set(this.dpMeals[i], 'totalStats', dayStats);
      }
      this.foodStatistics = foodStats;
      this.loadingDietPlan=false;

    },
    getAverage(type){
      let total = 0;
      for(let day of this.dpMeals){
        total += parseFloat(day.totalStats[type]);
      }
      // only count days with calories > 0 (don't count empty days)
      let totalDays = this.dpMeals.filter(x => parseFloat(x.totalStats['calories']) > 0).length

      return (total / totalDays).toFixed(2);
    },
    getOptions(type){
      let color;
      let tooltipVal;
      // let customFormatter;

      if(type === 'CALORIES'){
        color =  ['#3155A6'];
        tooltipVal = 'kcal';
      }

      if(type==='CARBS'){
        color = ['#1abc9c'];
        tooltipVal = 'g';
      }

      if(type==='PROTEINS'){
        color = ['#f7b84b'];
        tooltipVal = 'g';
      }

      if(type==='FATS'){
        color=['#f1556c'];
        tooltipVal='g';
      }

      if(type==='CARBS-PROTEINS'){
        color=['#1abc9c', '#f7b84b'];
        tooltipVal='g';
      }

      let diagramOpt = {
        chart: {
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset:false,
              customIcons: []
            },
          },
        },
        tooltip:{
          y: {
            formatter: (value) => { return value.toFixed(0) + tooltipVal },
          },
        },
        stroke: this.diagrams.stroke,
        xaxis: {
          categories: this.showNames ? this.dayNamesAlt : this.dayNamesShort,
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function(val) {
              return val.toFixed(0);
            }
          }
        },
        animations:{
          enabled: false
        }
      };

      diagramOpt.chart.id = type;
      diagramOpt.colors = color;
      return diagramOpt;
    },
    getSeries(type){
      if(type==='CALORIES'){
        return [{
          name: 'Θερμίδες',
          data:  [...this.dpMeals.map(x => {return x.totalStats.calories })]
        }]
      }else if(type==='PROTEINS'){
        return [{
          name: 'Πρωτεϊνη',
          data:  [...this.dpMeals.map(x => {return x.totalStats.proteins })]
        }]
      }else if(type==='CARBS'){
        return [{
          name: 'Υδατάνθρακες',
          data:  [...this.dpMeals.map(x => {return x.totalStats.carbs })]
        }]
      }else if(type==='FATS'){
        return [{
          name: 'Λίπη: ',
          data:  [...this.dpMeals.map(x => {return x.totalStats.fats })]
        }]
      }else if(type==='CARBS-PROTEINS'){
        return [{
          name: 'Υδατάνθρακες',
          data:  [...this.dpMeals.map(x => {return x.totalStats.carbs })]
        },
          {
            name: 'Πρωτεϊνες',
            data: [...this.dpMeals.map(x => {return x.totalStats.proteins })]
          }]
      }else if(type==='FOOD-PIE'){
        return this.foodStatistics
      }

    },
  }
}
</script>
