export default{
    title: 'Ημερολόγιο',
    sync: 'Συγχρονισμός με Google Calendar',
    stop: 'Διακοπή συγχρονισμού',
    activeSync: 'Ενεργός συγχρονισμός με',
    syncPaused: 'Ο συγχρονισμός έχει σταματήσει. Παρακαλώ ξανα-ενεργοποιήστε τα δικαιώματα πρόσβασης στο calendar κάνοντας κλικ παρακάτω.',
    notFunctioning: 'Εάν ο συγχρονισμός δεν λειτουργεί,',
    googleNoEdit:'Δεν μπορείτε να επεξεργαστείτε δεδομένα από το google calendar',

    edit:'Επεξεργασία ραντεβού',
    add: 'Προσθήκη νέου ραντεβού',
    cancelled: 'Ακυρωμένο ραντεβού'
}
