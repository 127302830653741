<template>

  <div id="comments">

    <div class="container">
      <div class="row">
        <div v-for="comment in api.rows" :key="comment.id" class="col-12">

          <div class="bg-white p-2 comment-item mb-3">
            - {{comment.comment}}
            <div class="user text-right">
              <span class="text-primary mr-2">{{formatDate(comment.createdAt)}}</span>
            <v-gravatar v-if="comment.user" :email="comment.user.email" alt="user-image" class="rounded-circle mr-2" default-img="mp" style="max-width:25px" />
              <strong>{{comment.user.firstName}} {{comment.user.lastName}}</strong>
            </div>

          </div>

        </div>
        <div v-if="api.rows.length ===0">Δεν βρέθηκαν σχόλια.</div>
      </div>

      <pagination-row :api="api" @fetchData="getData"></pagination-row>

      <div v-if="!commentSubmitted" class="comment-area mt-5">
        <b-form-textarea v-model="comment" placeholder="Το σχόλιό σας"></b-form-textarea>

        <div class="text-right">
          <b-button  variant="primary" class="mt-2" @click="sendComment" :disabled="isCommenting">Αποστολή</b-button>
          <p class="text-muted mt-2">
            Απαγορεύονται τα μηνύματα που έχουν ως αποτέλεσμα τη δημιουργία έριδων / κακής ατμόσφαιρας.<br/> Σεβαστείτε όλα τα μέλη ακόμη κι αν διαφωνείτε. Σεβαστείτε όλα τα πρόσωπα φυσικά ή νομικά ακόμη κι αν σας έχουν βλάψει.<br>
            <strong>Μη τήρηση των όρων μπορεί να οδηγήσει σε αποκλεισμό από το Athlisis Cloud.</strong>
          </p>
        </div>

      </div>
    </div>

  </div>
</template>
<style lang="scss" scoped>
.comment-item{
  border-radius:12px;
}
</style>

<script>
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";

export default{
  components: {PaginationRow},
  mixins: [PaginatedDataMixin],
  props:['listingId'],
  data(){
    return{
      isCommenting: false,
      comment: '',
      commentSubmitted: false
    }
  },
  created(){
    this.api.url = '/cloud/listings/'+this.listingId +'/comments';
    this.getData();
  },
  methods:{
    sendComment(){
      if(!this.comment || this.comment === '') return;
      this.isCommenting = true;
      this.$axios.post('/cloud/listings/'+this.listingId +'/comments', {
        comment: this.comment
      }).then(() => {
        this.comment = '';
        this.commentSubmitted = true;
        this.$notify({group: 'athlisis-notifications', type: 'success', title: 'Επιτυχία!', text: 'Το σχόλιο προστέθηκε επιτυχώς!'});
        this.getData();
      }).catch(e=>{
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: 'Κάτι πήγε λάθος!', text: 'Ο σχολιασμός απέτυχε. Παρακαλώ δοκιμάστε ξανά αργότερα!'});
      }).finally(()=>{
        this.isCommenting = false;
      });
    }
  }
}
</script>
