import axios from 'axios'
import {getSavedState, saveState} from "@utils/util";

export const state = {
    _dietPlanTags: getSavedState('tags.dietPlanTags') || null,
    _athleteTags: getSavedState('tags.athleteTags') || null,
    _mealTemplateTags: getSavedState('tags.mealTemplateTags') || null
};

export const getters = {};

export const mutations = {

    CACHE_DIET_PLAN_TAGS(state, tags){
        state._dietPlanTags = tags;
        saveState('tags.dietPlanTags', tags);
    },

    CACHE_ATHLETE_TAGS(state, tags){
        state._athleteTags = tags;
        saveState('tags.athleteTags', tags);
    },
    CACHE_MEAL_TEMPLATE_TAGS(state, tags){
        state._mealTemplateTags = tags;
        saveState('tags.mealTemplateTags', tags);
    },

    UPDATE_DIET_PLAN_TAG(state, payload){
        let index = state._dietPlanTags.findIndex(tag => tag.id === payload.id);
        state._dietPlanTags[index].text = payload.text;
        saveState('tags.dietPlanTags', state._dietPlanTags); // save to localStorage
    },

    UPDATE_ATHLETE_TAG(state, payload){
        let index = state._athleteTags.findIndex(tag => tag.id === payload.id);
        state._athleteTags[index].text = payload.text;
        saveState('tags.athleteTags', state._athleteTags); // save to localStorage
    },

    UPDATE_MEAL_TEMPLATE_TAG(state, payload){
        let index = state._mealTemplateTags.findIndex(tag => tag.id === payload.id);
        state._mealTemplateTags[index].text = payload.text;
        saveState('tags.mealTemplateTags', state._mealTemplateTags); // save to localStorage
    },

    REMOVE_DIET_PLAN_TAG(state, id){
        let index = state._dietPlanTags.findIndex(tag => tag.id === id);
        state._dietPlanTags.splice(index, 1);
        saveState('tags.dietPlanTags', state._dietPlanTags); // save to localStorage
    },

    REMOVE_ATHLETE_TAG(state, id){
        let index = state._athleteTags.findIndex(tag => tag.id === id);
        state._athleteTags.splice(index, 1);
        saveState('tags.athleteTags', state._athleteTags); // save to localStorage
    },

    REMOVE_MEAL_TEMPLATE_TAG(state, id){
        let index = state._mealTemplateTags.findIndex(tag => tag.id === id);
        state._mealTemplateTags.splice(index, 1);
        saveState('tags.mealTemplateTags', state._mealTemplateTags); // save to localStorage
    }

};

export const actions = {

    async init({ state, dispatch }) {
        // execute only if user is logged in
        if(localStorage.getItem('auth.token') !== 'null') {
            await dispatch('fetchCacheAthleteTags');
            await dispatch('fetchCacheDietPlanTags');
            await dispatch('fetchCacheMealTemplateTags');
        }

    },

    fetchCacheDietPlanTags({commit}){
        console.warn('Fetching diet plan tags...');

        return axios.get(process.env.VUE_APP_API + `/users/dp-tags`).then(result => {
            commit('CACHE_DIET_PLAN_TAGS', result.data);
        }).catch(e => {
            console.error('Diet Plan Tags fetching has failed!');
        })
    },

    fetchCacheAthleteTags({commit}){
        console.warn('Fetching athlete tags...');

        return axios.get(process.env.VUE_APP_API + `/users/athlete-tags`).then(result => {

            commit('CACHE_ATHLETE_TAGS', result.data);

        }).catch(e => {
            console.error('Athlete Tags fetching has failed!');
        })
    },

    fetchCacheMealTemplateTags({commit}){
        console.warn('Fetching meal template tags...');

        return axios.get(process.env.VUE_APP_API + `/users/meal-template-tags`).then(result => {

            commit('CACHE_MEAL_TEMPLATE_TAGS', result.data);

        }).catch(e => {
            console.error('Meal template tags fetching has failed!');
        })
    },

    updateAthleteTag({commit}, payload){
        return axios.put(`${process.env.VUE_APP_API}/users/athlete-tags/${payload.id}`, {text: payload.text}).then(result =>{
            commit('UPDATE_ATHLETE_TAG', payload);
        })
    },

    updateDietPlanTag({commit}, payload){
        return axios.put(`${process.env.VUE_APP_API}/users/dp-tags/${payload.id}`, {text: payload.text}).then(result =>{
            commit('UPDATE_DIET_PLAN_TAG', payload);
        })
    },

    updateMealTemplateTag({commit}, payload){
        return axios.put(`${process.env.VUE_APP_API}/users/meal-template-tags/${payload.id}`, {text: payload.text}).then(result =>{
            commit('UPDATE_MEAL_TEMPLATE_TAG', payload);
        })
    },


    removeDietPlanTag({commit}, id){
        return axios.delete(`${process.env.VUE_APP_API}/users/dp-tags/${id}`).then(()=>{
            commit('REMOVE_DIET_PLAN_TAG', id);
        }).catch(e=>{
            console.error(e);
        });
    },

    removeAthleteTag({commit}, id){
        return axios.delete(`${process.env.VUE_APP_API}/users/athlete-tags/${id}`).then(()=>{
            commit('REMOVE_ATHLETE_TAG', id);
        }).catch(e=>{
            console.error(e);
        });
    },

    removeMealTemplateTag({commit}, id){
        return axios.delete(`${process.env.VUE_APP_API}/users/meal-template-tags/${id}`).then(()=>{
            commit('REMOVE_MEAL_TEMPLATE_TAG', id);
        }).catch(e=>{
            console.error(e);
        });
    },

    refreshDPTagsCache({commit}){
        commit('CACHE_DIET_PLAN_TAGS', getSavedState('tags.dietPlanTags'));
    },

    refreshAthleteTagsCache({commit}){
        commit('CACHE_ATHLETE_TAGS', getSavedState('tags.athleteTags'));
    },

    refreshMealTemplateTags({commit}){
        commit('CACHE_MEAL_TEMPLATE_TAGS', getSavedState('tags.mealTemplateTags'));
    },


    reset({commit}){
        commit('CACHE_DIET_PLAN_TAGS', null);
        commit('CACHE_ATHLETE_TAGS', null);
        commit('CACHE_MEAL_TEMPLATE_TAGS', null);
    }
};
