<script>
import moment from "moment";
import localforage from "localforage";
import {mapState} from "vuex";

export default {

  computed:{
    ...mapState({
      foodMeasures: state => state.food._foodMeasures,
    })
  },
  data(){
    return{
      isEducational: !!process.env.VUE_APP_IS_EDUCATIONAL,
      newEvent:{
        date: null,
        startTime: null,
        endTime: null,
        duration: 30,
        endDate: null,
        notes: null,
        type: 1,
        title: '',
        selectedAthlete: null,
        sessionCost: 0,
        error: null,
        isSubmitting: false,
        existingClient: 1
      },
      dayNames: ['Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο', 'Κυριακή'],
      dayNamesShort: ['Δευ.', 'Τρ.', 'Τετ.', 'Πεμ.', 'Παρ.', 'Σαβ.', 'Κυρ.'],
      dayNamesAlt: ['1η Επιλογή', '2η Επιλογή', '3η Επιλογή', '4η Επιλογή', '5η Επιλογή', '6η Επιλογή', '7η Επιλογή'],
      dayMeals: ['Πρωϊνό', 'Δεκατιανό', 'Μεσημεριανό', 'Απογευματινό', 'Βραδινό'],
      elDatePicker: {
        days: ['Κυρ', 'Δευ', 'Τρ', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ'],
        months: ['Ιαν', 'Φεβ', 'Μαρ', 'Απρ', 'Μαϊος', 'Ιουν', 'Ιουλ', 'Αυγ', 'Σεπ', 'Οκτ', 'Νοε', 'Δεκ'],
        pickers: ['επόμενες 7 ημέρες', 'επόμενες 30 ημέρες', 'προηγούμενες 7 ημέρες', 'προηγούμενες 30 ημέρες'],
        placeholder: {
          date: 'Επιλογή ημερομηνίας:',
          dateRange: 'Επιλογή εύρους ημερομηνιών'
        }
      },
      quillOptions:{
        placeholder: "Εισάγετε σημειώσεις..",
        modules: {
          toolbar: {
            container: [["bold", "italic"],[{ 'list': 'ordered'}, { 'list': 'bullet' }],[{ 'header': [1, 2, 3, 4, 5, 6, false] }], [{ 'color': [] }, { 'background': [] }]],
          },
        }
      },
      microNutrientFields: ['saturatedFats', 'monounsaturatedFats', 'polyunsaturatedFats', 'cholesterol',
        'monodisaccharides', 'polysaccharides', 'dietaryFibers', 'water', 'alcohol', 'natrium',
        'potassium', 'calcium', 'magnesium', 'phosphorus', 'iron', 'zinc', 'retinol', 'carotene',
        'thiamine', 'riboflavin', 'vitaminB6', 'vitaminC', 'sugars', 'vitaminA',
        'caroteneA', 'caroteneB', 'cryptoxanthin', 'lycopene', 'lutein_zeaxanthin', 'niacin',
        'folicAcid', 'folateFood', 'folateDFE', 'folateTotal', 'choline', 'vitaminB12', 'vitaminB12Added',
        'vitaminD', 'vitaminE', 'vitaminK', 'copper', 'selenium', 'caffeine', 'theobromine'
      ],
      bothCategoriesMicronutrients: ['saturatedFats', 'monounsaturatedFats', 'polyunsaturatedFats', 'cholesterol', 'dietaryFibers', 'water', 'alcohol', 'natrium',
        'potassium', 'calcium', 'magnesium', 'phosphorus', 'iron', 'zinc', 'retinol',
        'thiamine', 'riboflavin', 'vitaminB6', 'vitaminC'],
      trixopoulouMicronutrients: ['monodisaccharides', 'polysaccharides', 'carotene'],
      usdaMicronutrients: ['sugars', 'vitaminA',
        'caroteneA', 'caroteneB', 'cryptoxanthin', 'lycopene', 'lutein_zeaxanthin', 'niacin',
        'folicAcid', 'folateFood', 'folateDFE', 'folateTotal', 'choline', 'vitaminB12', 'vitaminB12Added',
        'vitaminD', 'vitaminE', 'vitaminK', 'copper', 'selenium', 'caffeine', 'theobromine'],
      microNutrientTranslation: {
        saturatedFats: 'Κορεσμένα Λιπαρά (g)',
        monounsaturatedFats: 'Μονοακόρεστα Λιπαρά (g)',
        polyunsaturatedFats: 'Πολυακόρεστα λιπαρά (g)',
        cholesterol: 'Χοληστερόλη (mg)',
        monodisaccharides: 'Μονοδισακχαρίτες (g)',
        polysaccharides: 'Πολυσακχαρίτες (g)',
        dietaryFibers: 'Διαιτητικές Ίνες (g)',
        water: 'Νερό (g)',
        alcohol: 'Αλκοόλη (g)',
        natrium: 'Νάτριο (mg)',
        potassium: 'Κάλιο (mg)',
        calcium: 'Ασβέστιο (mg)',
        magnesium: 'Μαγνήσιο (mg)',
        phosphorus: 'Φώσφορος (mg)',
        iron: 'Σίδηρος (mg)',
        zinc: 'Ψευδάργυρος (mg)',
        retinol: 'Ρετινόλη (mcg)',
        carotene: 'Καροτίνη (mcg)',
        thiamine: 'Θειαμίνη (mg)',
        riboflavin: 'Ριβοφλαβίνη (mg)',
        vitaminB6: 'Βιταμίνη Β6 (mg)',
        vitaminE: 'Βιταμίνη Ε (mg)',
        vitaminC: 'Βιταμίνη C (mg)',
        sugars: 'Σάκχαρα (g)',
        vitaminA: 'Βιταμίνη Α (mcg)',
        caroteneA: 'Καροτίνη Α (mcg)',
        caroteneB: 'Καροτίνη Β (mcg)',
        cryptoxanthin: 'Κρυπτοξανθίνη Β (mcg)',
        lycopene: 'Λυκοπένιο (mcg)',
        lutein_zeaxanthin: 'Λουτεΐνη + ζεαξανθίνη (mcg)',
        niacin: 'Νιασίνη (mg)',
        folicAcid: 'Φολικό οξύ (mcg)',
        folateFood: 'Φολικό, τρόφιμο (mcg)',
        folateDFE: 'Φολικό, DFE (mcg)',
        folateTotal: 'Φολικό, σύνολο (mcg)',
        choline: 'Χολίνη (mg)',
        vitaminB12: 'Βιταμίνη Β12 (mcg)',
        vitaminB12Added: 'Βιταμίνη Β12, πρόσθετη (mcg)',
        vitaminD: 'Βιταμίνη D (D2+D3) (mcg)',
        vitaminK: 'Βιταμίνη Κ (mcg)',
        copper: 'Χαλκός (mg)',
        selenium: 'Σελήνιο (mcg)',
        caffeine: 'Καφεΐνη (mg)',
        theobromine: 'Θεοβρωμίνη (mg)',
      },
    }
  },

  methods: {
    formatDriObject(gender, age){
      let fixedGender = gender === 'M' ? 'male' : 'female';
      let fixedAge = null;

      let ageRanges = ['7–12', '1–3', '4–8', '9–13', '14–18', '19–30', '31–50', '51–70', '71'];

      if(age < 9 ) fixedGender = 'children'
      else if(age < 1 ) fixedGender = 'infants'

      if(age < 1){
        fixedAge = ageRanges[0]
      }else if(age < 4){
        fixedAge = ageRanges[1]
      }else if(age < 9){
        fixedAge = ageRanges[2]
      }else if (age < 14){
        fixedAge = ageRanges[3]
      }else if(age < 19){
        fixedAge = ageRanges[4]
      }else if(age < 31){
        fixedAge = ageRanges[5]
      }else if(age < 51){
        fixedAge = ageRanges[6]
      }else{
        fixedAge = ageRanges[7]
      }

      return {
        age: fixedAge,
        gender: fixedGender
      }
    },
    formatName(mealFood){

      if(mealFood['mealFoods.id']){
        if(mealFood['mealFoods.hasAltName']) return mealFood['mealFoods.altName'];
        else return mealFood.name
      }
      if(mealFood.mealFoods){
        return mealFood.mealFoods.hasAltName ? mealFood.mealFoods.altName : mealFood.food.name;
      }else{
        return mealFood.hasAltName ? mealFood.altName : mealFood.food.name;
      }
    },
    formatFoodUnits(food){

      let print = Number.isInteger(food.quantity) ? food.quantity: food.quantity.toFixed(1);
      let key = print;

      // check if measurementUnit ID isn't grammars, if so then change key to greek letters instead of 0.5
      if(food.measurementUnit !== 8){
        print = this.replaceFoodUnits(key);
      }
      let measure = this.foodMeasures.find(x=> x.id === food.measurementUnit);
      return measure.displayName ? print + ' ' + measure.displayName : print;
    },
    getDate(date){
      if(date) return moment(date).format('DD-MM-YYYY');
      return moment().format('DD-MM-YYYY');
    },

    replaceFoodUnits: unitValue => {
      unitValue = parseFloat(unitValue);
      if(unitValue === 0.25) return '1/4';
      if(unitValue === 0.33) return '1/3';
      if(unitValue === 0.5) return '1/2';
      if(unitValue === 0.66) return '2/3';
      if(unitValue === 0.75) return '3/4';

      return unitValue;
    },

    replaceCommaWithDot: value => {
      return value.replace(",", ".");
    },

    formatDate: value => {
      return moment(value).format('DD/MM/YYYY HH:mm');
    },

    formatDateWithoutHour: value => {
      return moment(value).format('DD/MM/YYYY');
    },

    formatDateWithoutHourAsLink: value => {
      return moment(value).format('DD-MM-YYYY');
    },
    async fetchFoods(){
      let foods = await localforage.getItem('food.foods');

      if(localStorage.getItem('food.foodUpdateRequired')){
        await this.$store.dispatch('food/fetchCacheFoods');
        localStorage.removeItem('food.foodUpdateRequired');
      }
      if(!foods || foods.length === 0) {
        return this.$store.dispatch('food/fetchCacheFoods')
      }else{
        return this.$store.dispatch('food/refreshCacheFoods');
      }
    },

    async fetchFoodMeasures(){
      if(!this.foodMeasures) {
        await this.$store.dispatch('food/fetchCacheFoodMeasures')
      }else{
        await this.$store.dispatch('food/refreshCacheFoodMeasures');
      }
    },

  }
}
</script>
