import axios from 'axios'


export const state = {
    measurements: [],
};

export const getters = {};

export const mutations = {
    CACHE_MEASUREMENTS(state, measurements){
        state.measurements = measurements;
    }
};

export const actions = {

    fetchAndCacheMeasurements({commit, state}){
        if(!state.measurements || state.measurements.length < 1) {
            axios.get(process.env.VUE_APP_API + `/general/measurements`).then(result => {
                commit('CACHE_MEASUREMENTS', result.data);
                return result.data;
            });
        }
    }
};
