import axios from 'axios'
import moment from "moment"
import localforage from "localforage";
import dispatchActionForAllModules from "@utils/dispatch-action-for-all-modules";
import {getSavedState, saveState} from "@utils/util";
// import router from '@src/router/index'

export const state = {
  currentUser: getSavedState('auth.currentUser'),
  token: getSavedState('auth.token'),
  printTemplates: getSavedState('auth.printTemplates'),
  questionnaires: getSavedState('auth.questionnaires') || [],
  mobileToken: null
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    saveState('auth.currentUser', newValue);
    setDefaultAuthHeaders(state);
  },

  SET_CURRENT_USER_LOGO(state, value){
    state.currentUser.logo = value;
    saveState('auth.currentUser', state.currentUser);
  },

  SET_CURRENT_USER_PROFILE_IMAGE(state, value){
    state.currentUser.image = value;
    saveState('auth.currentUser', state.currentUser);
  },

  SET_PURCHASE_STATE_FALSE(state){
    state.currentUser.isPremium = false;
    saveState('auth.currentUser', state.currentUser)
  },
  SET_PURCHASE_STATE_TRUE(state){
    state.currentUser.isPremium = true;
    saveState('auth.currentUser', state.currentUser)
  },

  SET_PRINT_TEMPLATES(state, value){
    state.printTemplates = value;
    saveState('auth.printTemplates', state.printTemplates);
  },

  SET_QUESTIONNAIRES(state, value){
    state.questionnaires = value;
    saveState('auth.questionnaires', state.questionnaires);
  },

  UPDATE_SUBSCRIPTION_DATE(state, value){
    state.currentUser.subscriptionDue = value;
    saveState('auth.currentUser', state.currentUser)
  },

  SET_CURRENT_USER_TOKEN(state, newValue) {
    state.token = newValue;
    saveState('auth.token', newValue);
    setDefaultAuthHeaders(state);
  },

  SET_MOBILE_TOKEN(state, token){
    state.mobileToken = token;
    setDefaultAuthHeaders(state);
  },

  UPDATE_CURRENT_USER_COMPANY(state, newValue) {
    state.currentUser.userCompany = newValue;
    saveState('auth.currentUser', state.currentUser)
  },

  ENABLE_USER_EMAIL(state){
    state.currentUser.isActivated = true;
    saveState('auth.currentUser', state.currentUser);
  },

  UPDATE_CURRENT_USER_TO_DOS(state, newValue) {
    state.currentUser.userToDo.unshift(newValue);
    saveState('auth.currentUser', state.currentUser)
  },
  SWITCH_CURRENT_USER_TO_DO(state, todoId) {
    const foundIndex = state.currentUser.userToDo.findIndex(
        (x) => x.id === todoId
    );
    state.currentUser.userToDo[foundIndex].isCompleted = !state.currentUser.userToDo[foundIndex].isCompleted;
    saveState('auth.currentUser', state.currentUser);
  },

  DELETE_CURRENT_USER_TO_DO(state, todoId) {
    state.currentUser.userToDo = state.currentUser.userToDo.filter(
        (x) => x.id !== todoId
    );
    saveState('auth.currentUser', state.currentUser)
  },

  ADD_NEW_TEMPLATE(state, newTemplate){
    state.printTemplates.push(newTemplate);
    state.printTemplates.sort( (a, b) => { return b.id - a.id});
    saveState('auth.printTemplates', state.printTemplates);
  },

  ADD_NEW_QUESTIONNAIRE(state, newQuestionnaire){
    state.questionnaires.push(newQuestionnaire);
    saveState('auth.questionnaires', state.questionnaires);
  },

  UPDATE_TEMPLATE(state, newTemplate){
    state.printTemplates.splice(state.printTemplates.findIndex(item => {
      return item.id === newTemplate.id;
    }), 1);
    state.printTemplates.push(newTemplate);
    state.printTemplates.sort( (a, b) => { return b.id - a.id});
    saveState('auth.printTemplates', state.printTemplates);
  },

  UPDATE_QUESTIONNAIRE(state, newQuestionnaire){
    state.questionnaires.splice(state.questionnaires.findIndex(item => {
      return item.id === newQuestionnaire.id;
    }), 1);
    state.questionnaires.push(newQuestionnaire);
    saveState('auth.questionnaires', state.questionnaires);
  },

  DELETE_TEMPLATE(state, id){
    state.printTemplates.splice(state.printTemplates.findIndex(item => {
      return item.id === id;
    }), 1);
    saveState('auth.printTemplates', state.printTemplates);
  },

  DELETE_QUESTIONNAIRE(state, id){
    state.questionnaires.splice(state.questionnaires.findIndex(item => {
      return item.id === id;
    }), 1);
    saveState('auth.questionnaires', state.questionnaires);
  },

  SET_STARRED_TEMPLATE(state, id){
    for(let template of state.printTemplates){
      // if(template)
      template.isStarred = false;
      if(template.id === id){
        template.isStarred = true;
      }
    }
    saveState('auth.printTemplates', state.printTemplates);
  }
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser && !!state.token && state.token.length > 0
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    setDefaultAuthHeaders(state);
    dispatch('getQuestionnaires');
    dispatch('validate');
    dispatch('generateUserMobileToken');
    dispatch('refetchUser');
  },
  cacheUser({commit}, data){
    commit('SET_CURRENT_USER', data.user);
    commit('SET_CURRENT_USER_TOKEN', data.token);
  },

  // Logs out the current user.
  logOut({ commit }) {
    dispatchActionForAllModules('reset');
    let version = localStorage.getItem('version');
    localStorage.clear();
    localStorage.setItem('version', version);
    localforage.clear().then();
  },

  updateUser({ commit }, newUser) {
    return axios.put(`${process.env.VUE_APP_API}/users/`, newUser).then((response) => {
      let newUsr = Object.assign({}, state.currentUser);
      newUsr.firstName = newUser.firstName;
      newUsr.lastName = newUser.lastName;
      newUsr.biography = newUser.biography;
      newUsr.image = newUser.image;
      newUsr.userSocialMedia = {
        instagram: newUser.userSocialMedia.instagram,
        facebook: newUser.userSocialMedia.facebook,
        twitter: newUser.userSocialMedia.twitter,
        linkedIn: newUser.userSocialMedia.linkedIn,
        website: newUser.userSocialMedia.website,
      };

      commit('SET_CURRENT_USER', newUsr);
      return response.data
    })
  },

  generateUserMobileToken({commit, dispatch}) {
    return axios.get(`${process.env.VUE_APP_API}/mobile/token`).then(res=>{
      commit('SET_MOBILE_TOKEN', res.data);
      dispatch('chat/getRecentChats', {}, {root: true});
      return res.data;
    }).catch();
  },

  updateUserLogo({commit}, logoUrl){
    return axios.post(`${process.env.VUE_APP_API}/users/logo`, {logo: logoUrl}).then((res) => {
      commit('SET_CURRENT_USER_LOGO', logoUrl);
      return res;
    })
  },

  updateUserImage({commit}, image){
    commit('SET_CURRENT_USER_PROFILE_IMAGE', image);
  },

  refetchUser({commit}){
    return axios.get(process.env.VUE_APP_API + `/users/`).then(result => {
      commit('SET_CURRENT_USER', result.data.user);
      return result;
    })
  },

  verifyEmail({commit}){
    commit('ENABLE_USER_EMAIL');
  },

  updateUserCompany({ commit }, userCompany) {
    return axios
        .put(`${process.env.VUE_APP_API}/users/user-company`, userCompany)
        .then((response) => {
          commit('UPDATE_CURRENT_USER_COMPANY', userCompany);
          return response.data
        })
  },

  addToDo({ commit }, toDo) {
    return axios.post(`${process.env.VUE_APP_API}/users/todos`, toDo).then((response) => {
      commit('UPDATE_CURRENT_USER_TO_DOS', response.data);
      return response.data
    })
  },

  switchToDoStatus({ commit }, { id }) {
    return axios.put(`${process.env.VUE_APP_API}/users/todo/` + id).then((response) => {
      commit('SWITCH_CURRENT_USER_TO_DO', id);
      return response.data
    })
  },

  deleteUserToDo({ commit }, { id }) {
    return axios.delete(`${process.env.VUE_APP_API}/users/todo/` + id).then((response) => {
      commit('DELETE_CURRENT_USER_TO_DO', id);
      return response.data
    })
  },

  // register the user
  register({ commit, dispatch, getters }, { firstName, lastName, email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return axios.post(`${process.env.VUE_APP_API}/users/register`, {firstName, lastName, email, password,}).then((response) => {
      return response.data
    })
  },

  // register the user
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return axios.post(`${process.env.VUE_APP_API}/users/forgot-password`, { email }).then((response) => {
      return response.data
    })
  },

  // Validates the current user's token and logs out if it isn't good!
  validate({ commit, state }) {
    // @todo: CHECK HERE. SOMETHING SEEMS WRONG!
    if (!state.currentUser){
      commit('SET_CURRENT_USER', null);
      commit('SET_CURRENT_USER_TOKEN', null);
      return Promise.resolve(null);
    }
    return state.currentUser;

  },

  notPurchasedState({commit, state}){
    commit('SET_PURCHASE_STATE_FALSE', null);
    return state.currentUser;
  },

  purchasedState({commit, state}){
    commit('SET_PURCHASE_STATE_TRUE', null);
    return state.currentUser;
  },

  validateAdmin({state}){
    if (!state.currentUser) return Promise.resolve(null);

    return axios.get(process.env.VUE_APP_API + `/users/is-valid-admin`);

  },

  updateSubscriptionDueDate({commit, state}){
    let dueDate = moment().add(1, 'year')
    commit('UPDATE_SUBSCRIPTION_DATE', dueDate);
    return state.currentUser;
  },

  getPrintTemplates({commit, state}) {
    return axios.get(process.env.VUE_APP_API + `/users/print-templates`).then(res => {
      commit('SET_PRINT_TEMPLATES', res.data.templates);
    });
  },
  getQuestionnaires({commit, state}) {
    return axios.get(process.env.VUE_APP_API + `/users/questionnaires`).then(res => {
      commit('SET_QUESTIONNAIRES', res.data);
    });
  },

  createPrintTemplate({commit, state}){
    return axios.post(`${process.env.VUE_APP_API}/users/print-templates`).then(res =>{
      commit('ADD_NEW_TEMPLATE', res.data.template);
      return Promise.resolve(res);
    })
  },

  createQuestionnaire({commit, state}){
    return axios.post(`${process.env.VUE_APP_API}/users/questionnaires`).then(res =>{
      commit('ADD_NEW_QUESTIONNAIRE', res.data);
      return Promise.resolve(res);
    })
  },

  updatePrintTemplate({commit, state}, payload){
    return axios.put(`${process.env.VUE_APP_API}/users/print-templates/` + payload.id, {template: payload}).then ( res => {
      commit('UPDATE_TEMPLATE', res.data.template);
    }).catch(err => {
      return Promise.reject(err);
    })
  },

  updateQuestionnaire({commit, state}, payload){
    return axios.put(`${process.env.VUE_APP_API}/users/questionnaires/` + payload.id, payload).then ( res => {
      commit('UPDATE_QUESTIONNAIRE', res.data);
    }).catch(err => {
      return Promise.reject(err);
    })
  },

  deletePrintTemplate({commit, state}, payload){
    return axios.delete(`${process.env.VUE_APP_API}/users/print-templates/` + payload.id).then ( res => {
      commit('DELETE_TEMPLATE', payload.id);
      return Promise.resolve(res);
    }).catch( err => {
      return Promise.reject(err)
    })
  },

  deleteQuestionnaire({commit, state}, payload){
    return axios.delete(`${process.env.VUE_APP_API}/users/questionnaires/` + payload.id).then ( res => {
      commit('DELETE_QUESTIONNAIRE', payload.id);
      return Promise.resolve(res);
    }).catch( err => {
      return Promise.reject(err)
    })
  },

  setStarredPrintTemplate({commit, state}, payload){
    return axios.patch(`${process.env.VUE_APP_API}/users/print-templates/` + payload.id).then (res => {
      commit('SET_STARRED_TEMPLATE', payload.id);
      return Promise.resolve(res);
    }).catch(err => {
      return Promise.reject(err);
    })
  },

  reset({commit}){
    commit('SET_CURRENT_USER', null);
    commit('SET_CURRENT_USER_TOKEN', null);
    commit('SET_PRINT_TEMPLATES', null);
    commit('SET_QUESTIONNAIRES', null);
    commit('SET_MOBILE_TOKEN', null)
  }




};

// ===
// Private helpers
// ===

function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common['x-access-token'] = state.token ? state.token : ''
  axios.defaults.headers.common['x-dietitian-token'] = state.mobileToken ? state.mobileToken : ''
}
