import Vue from 'vue'
import {BootstrapVue} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Notifications from 'vue-notification'
import VuejsDialog from 'vuejs-dialog';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import Gravatar from 'vue-gravatar';
import localforage from "localforage";
import App from './app'
import router from '@router'
import store from '@state/store'
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import AthlisisMixin from "@src/mixins/BaseMixin";
import EducationalComponent from "@components/EducationalComponent";
import i18n from "./utils/setupi18n";
import axios from "axios";
import SaveButton from "@components/elements/SaveButton";
import DeleteButton from "@components/elements/DeleteButton";

import VCalendar from 'v-calendar';
Vue.use(BootstrapVue);

Vue.use(VCalendar);

const currentVersion = "3.3";

if(!localStorage.getItem('version') || localStorage.getItem('version') !== currentVersion){
  localStorage.setItem('version', currentVersion);
  localStorage.setItem('food.foodUpdateRequired', 'true');
}

localforage.config({name: 'athlisis'});

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production';

Vue.component('v-gravatar', Gravatar);
Vue.component('save-button', SaveButton);
Vue.component('delete-button', DeleteButton);
Vue.use(Notifications);
Vue.use(VuejsDialog);


Vue.component('apexchart', VueApexCharts);
Vue.component('v-select', vSelect);

const axiosInstance = axios.create({baseURL: process.env.VUE_APP_API});

Vue.prototype.$axios = axiosInstance;

Vue.mixin(AthlisisMixin);

if(!!process.env.VUE_APP_IS_EDUCATIONAL){
  Vue.component('educational-unavailable', EducationalComponent);
}

const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
