<template>

    <b-button :type="type" variant="danger" class="btn" :disabled="disabled" @click="$emit('click')">

      <div class="d-flex align-items-center">
        <span class="icon-trash mr-1"></span> {{ text || $t('delete') }}
      </div>

    </b-button>



</template>

<script>
export default{

  props:{
    type: {
      type: String,
      default: null
    },
    text: {
      type: String
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },


}
</script>