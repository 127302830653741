<script>
import TopBar from '@components/top-bar'
import Footer from '@components/footer'
import OnlineStatus from "@components/global/OnlineStatus";
import ChatSidebar from "@views/chat/ChatSidebar";
import {mapState} from "vuex";

export default {
  components: { TopBar, Footer, OnlineStatus, ChatSidebar },
  data() {
    return {
      isMenuOpened: false,
      activeSection: 0
    }
  },
  mounted(){

    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
  },
  beforeDestroy() {
    if (window.Tawk_API) {
      window.Tawk_API.showWidget();
    }
  },
  computed:{
    ...mapState({
      selectedUser: state => state.chat.selectedUser,
    })
  },
  methods: {
    toggleMenu() {
      this.isMenuOpened = !this.isMenuOpened
    }
  },
}
</script>

<style lang="scss">
#chat-home {
  height:100vh;
  overflow:hidden;
  padding-top: $topbar-height;


  .chat-content {
    padding:0;
    background-color:#fff;
    @media(max-width:767px){
      display:none;
      &.active{
        display:block;
      }
    }
  }

  .chat-sidebar-layout-elem{
    @media(max-width:767px){
      display:none;
      &.active{
          display:block;
      }
    }
  }

}
</style>
<template>
  <div>
    <header id="topnav">
      <TopBar :is-menu-opened="isMenuOpened"/>
    </header>
    <div v-if="!isEducational" id="chat-home" class="row">
      <chat-sidebar class="chat-sidebar-layout-elem " :class="selectedUser ? '' : 'active'"/>

      <div class="col-12 col-md-8 col-lg-9 chat-content p-0 m-0" :class="selectedUser ? 'active' : ''">

        <slot/>
      </div>
    </div>
    <educational-unavailable id="chat-home" v-else/>
<!--      <Footer />-->
      <OnlineStatus/>
  </div>
</template>
