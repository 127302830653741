<script>

export default {
  page: {
    title: '404 - Η σελίδα δεν βρέθηκε',
    meta: [{ name: 'Η σελίδα δεν βρέθηκε', content: '404' }],
  },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
    data(){
      return {
          prevRoute: null
      }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
}
</script>

<template>
  <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">

                <div class="card-body p-4">

                    <div class="error-ghost text-center">
                        <img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="250"/>
                    </div>

                    <div class="text-center">
                        <h3 class="mt-4 text-uppercase font-weight-bold">Η ΣΕΛΙΔΑ ΔΕΝ ΒΡΕΘΗΚΕ!</h3>
                        <p class="text-muted mb-0 mt-3" style="line-height: 20px;">Η σελίδα που αναζητήσατε δεν βρέθηκε. Εάν πιστεύετε ότι η σελίδα θα έπρεπε να υπάρχει, ενημερώστε μας με email στο issues@athlisis.com.</p>

                        <b-button variant="primary" class="btn btn-primary mt-3" @click="$router.push(prevRoute)"><span class="icon-directions mr-1"></span> Επιστροφή στην τελευταία σελίδα</b-button>
                    </div>

                </div> <!-- end card-body -->
            </div>
            <!-- end card -->

        </div> <!-- end col -->
  </div>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
