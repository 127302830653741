<template>
  <div id="kids-bmi">
    <h5>Πιθανότητα αντίστασης στην ινσουλίνη</h5>
<!--    <span class="text-muted">Χαρακτηρισμός παιδιών από 2 έως 18 ετών με βάση το BMI τους.</span>-->

    <div class="row mt-4">
      <div class="col-md-6">
        <b-form-group label="Τριγλυκερίδια νηστείας" label-for="edit-triglycerides">
          <b-input-group append="mg/dl" >
            <b-form-input id="edit-triglycerides" v-model="triglycerides" type="text" placeholder="Τριγλυκερίδια νηστείας (mg/dl)"
                          @input="triglycerides = replaceCommaWithDot(triglycerides)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="Γλυκόζη νηστείας" label-for="edit-glucose">
          <b-input-group append="mg/dl" >
            <b-form-input id="edit-glucose" v-model="glucose" type="text" placeholder="Γλυκόζη νηστείας (mg/dl)"
                          @input="glucose = replaceCommaWithDot(glucose)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-12 text-right">
        <b-button variant='primary' @click="calculate">Υπολογισμός πιθανότητας</b-button>
      </div>
      <div v-if="result" class="col-md-12 text-center mt-3">


        Ο δείκτης TyG είναι ίσος με: <strong>{{tyg}}</strong>
        <h4>Υπάρχει <strong><u>{{result}}</u></strong> πιθανότητα αντίστασης στην ινσουλίνη</h4>
        <h4 v-if="parseFloat(tyg) >= 8.5">Υπάρχει πιθανότητα ύπαρξης Λιπώδης Διήθησης (NAFLD)</h4>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      triglycerides: null,
      glucose: null,
      result: null,
      tyg: null
    }
  },
  methods:{
    calculate(){
      this.tyg = parseFloat(this.triglycerides) * parseFloat(this.glucose) / (2 * 1000);
      if(this.tyg >= 4.49){
        this.result = 'υψηλή';
      }else{
        this.result = 'χαμηλή'
      }
    }
  }
}

</script>
