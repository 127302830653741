<template>
  <div class="col-12 col-md-4 col-lg-3 chat-users-list">

    <div>
      <b-form-input v-model="search" placeholder="Αναζήτηση πελάτη.." debounce="400"></b-form-input><br/>

      <div class="chat-list-wrapper">
        <h4 v-if="!search || search.length === 0" class="text-primary">Πρόσφατες συνομιλίες</h4>

        <div v-if="!search || search.length === 0" v-for="user in recentChats" class="card user-item-card"
             :class="user && user.user && selectedUser === user.user.id ? 'current' : ''" @click="setSelectedUser(user)">

          <div class="row  align-items-center" :class="user.isUnread ? 'unread' : ''">

            <div class="col-10">
              <div class="user-thumbnail">

                <img v-if="user.user.image" :src="user.user.image" class="rounded-image">

                <span v-else>
                  {{formatThumbnail(user.user.firstName)}}
                  {{formatThumbnail(user.user.lastName)}}
                </span>
              </div>
              <h6 class="text-primary">
                {{user.user.firstName}} {{user.user.lastName}}
                <p class="text-primary-muted">

                  {{user.text}}
                </p>
              </h6>
            </div>
            <div class="col-2">
              <div class="unread-circle" v-if="user.isUnread">
                <svg width="15px" height="15px" viewBox="0 0 36 36" version="1.1"
                     preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>circle-solid</title>
                  <path d="M18,4A14,14,0,1,0,32,18,14,14,0,0,0,18,4Z" class="clr-i-solid clr-i-solid-path-1" fill="#3155A6FF"></path>
                  <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>
                </svg>
              </div>
            </div>


          </div>



        </div>

        <h4 v-if="search && search.length > 0" class="text-primary">Λίστα πελατών</h4>
        <div v-if="search && search.length > 0" v-for="user in users" class="card user-item-card " :class="selectedUser === user.mobileClientId ? 'current' : ''"
             @click="setSelectedUser(user)">

          <div class="align-items-center">

            <div class="user-thumbnail">
              <img v-if="user.image" :src="user.image" class="rounded-image">
              <span v-else>
                  {{formatThumbnail(user.firstName)}}
              {{formatThumbnail(user.lastName)}}
            </span>

            </div>
            <h6 class="text-primary">
              {{user.firstName}} {{user.lastName}}
            </h6>

          </div>


        </div>

      </div>

    </div>
  </div>
</template>

<style lang="scss">
.chat-users-list{
  height:calc(100vh - $topbar-height);
  padding: 15px 15px!important;
  overflow-y:auto;
  background-color: $color_light_bg;
  @media(max-width:767px){
    display:none;
  }
  @media(min-width:768px){
    border-right:1px solid $color_primary_low_opacity;
  }

}
.user-thumbnail{
  float:left;
  margin-top:5px;
  margin-right:10px;
  width:25px;
  height:25px!important;
  border-radius:50%;
  display:flex;
  align-items:center;
  justify-content: center;
  background: $color_primary;
  color:#fff;
  font-size:0.5rem;
  font-weight:700;
}
.user-item-card{
  .user-thumbnail{
    margin-top:12px;
  }
  padding:6px!important;
  margin-bottom:10px!important;
  //border-radius:0;
  .unread{
    h6{
      font-weight:700;
    }
    .text-primary-muted{
      font-weight:700;
    }


  }
  h6{
    font-weight:400;

  }
  &.current{
    background-color:$color-primary;
    .user-thumbnail{
      background-color:white!important;
      color:$color-primary!important;
    }
    .text-primary{
      color:#fff!important;
    }
  }
  &:hover{
    background-color:$color-primary;
    cursor:pointer;
    .user-thumbnail{
      background-color:white!important;
      color:$color-primary!important;
    }
    .text-primary{
      color:#fff!important;
    }

  }

}
</style>

<script>

import {mapState} from "vuex";

export default{

  components: {},
  computed: {
    ...mapState({
      user: state => state.auth.currentUser,
      selectedUser: state => state.chat.selectedUser,
      recentChats: state => state.chat.recentChats
    }),
    currUrl(){
      return this.$route.name
    },
  },
  watch:{
    search(newVal){
      this.fetchUsersList();
    },
    selectedUser(val){
      if(val){
        this.$emit('userSelected', true);
      }else{
        this.$emit('userSelected', false)
      }
    }
  },
  data(){
    return{
      search: '',
      users: []
    }
  },

  methods:{
    getUserFromId(id){
      return this.users.find(x => x.mobileClientId === id);
    },
    formatThumbnail(name){
      return name ? name.substr(0,1).toUpperCase() : '';
    },
    fetchUsersList(){
      this.$axios.get('/mobile/chat/user-list', {params:{search: this.search}}).then(res =>{
        this.users = res.data;
      })
    },

    setSelectedUser(user){
      if(!user.hasOwnProperty('user')){
        user.user = {...user};
        user.user.id = user.mobileClientId
      }
      this.$store.dispatch('chat/setSelectedUser', user.user.id); // re-fetch and store data!
      this.$store.dispatch('chat/setSelectedUserInfo', {
        thumbnail: this.formatThumbnail(user.user.firstName) + '' + this.formatThumbnail(user.user.lastName),
        image: user.user.image,
        fullName: user.user.firstName + ' ' + user.user.lastName,
        email: user.user.email,
        id: user.user.id
      });
    },
  },
}
</script>
