<template>
  <div id="kids-bmi" >

    <h5>Υπολογισμός Δείκτη Μάζας Σώματος</h5>
    <span class="text-muted">Χαρακτηρισμός επιπέδου υγείας με βάση τον Δ.Μ.Σ.</span>

    <div class="row mt-2">
      <div class="col-md-6">
        <b-form-group label="Ύψος:" label-for="edit-height">
          <b-input-group append="cm">
            <b-form-input id="edit-height" v-model="height" type="text" placeholder="Εισάγετε το ύψος σε εκατοστά"
                          @input="height = replaceCommaWithDot(height)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="Βάρος:" label-for="edit-weight">
          <b-input-group append="kg" >
            <b-form-input id="edit-weight" v-model="weight" type="text" placeholder="Εισάγετε το βάρος σε κιλά (kg)"
                          @input="weight = replaceCommaWithDot(weight)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-12 text-right">
        <b-button variant='primary' @click="calculate">Υπολογισμός Δ.Μ.Σ.</b-button>
      </div>
      <div v-if="bmi" class="col-md-12 text-center mt-3">
        <b-alert :variant="resultColor" show><strong><h4>{{result}}</h4></strong> Το BMI είναι: {{bmi.toFixed(1)}}</b-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default{

  data(){
    return {
      height: null,
      weight: null,
      bmi: null,
      result: null,
      resultColor: '',
      results: ['Ελλιποβαρής','Φυσιολογικό Βάρος','Υπέρβαρος','Παχύσαρκος Ι', 'Παχύσαρκος ΙΙ', 'Νοσογόνος Παχυσαρκία'],
      resultColors: ['warning', 'success', 'warning', 'danger', 'danger', 'danger'],
    }
  },

  methods:{
    calculate(){
        this.bmi = this.weight / Math.pow(this.height/100, 2);
        let index = 0;
        if(this.bmi < 18.4) index = 0;
        else if(this.bmi < 24.9) index=1;
        else if(this.bmi < 29.9) index = 2;
        else if(this.bmi < 34.9) index=3;
        else if(this.bmi < 39.9) index =4;
        else index=5;

        this.result = this.results[index];
        this.resultColor = this.resultColors[index];
    }
  }
}
</script>
