<script>
import axios from "axios";

export default {
  components: {},

  data(){
    return{
      issue: null,
      description: null,
      submittingIssue: false,
      error: null
    }
  },

  methods:{
    openSubmitIssueModal(){
      this.$refs['issue-modal'].show();
    },

    submitIssue(){
      this.error = null;
      if(!this.issue){
        this.error = 'Παρακαλώ εισάγετε κάποιο ζήτημα';
        return;
      }

      if(!this.description){
        this.error = 'Παρακαλώ περιγράψτε μας το πρόβλημά σας!'
        return;
      }

      this.submittingIssue = true;

      axios.post(`${process.env.VUE_APP_API}/users/issue`, {issue: this.issue, description: this.description}).then(result=>{
        this.$notify({group: 'athlisis-notifications', duration: 5000, type:'success', title: this.$t('success.title'), text: 'Το αίτημά σας στάλθηκε επιτυχώς. Θα επικοινωνήσουμε μαζί σας σύντομα!'});
        this.$refs['issue-modal'].hide();
      }).catch(e=>{
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η αποστολή των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      });
    }
  }
}
</script>

<template>
  <!-- Footer Start -->
  <footer id="footer" class="footer">
    <b-modal id="issue-modal" ref="issue-modal" title="Αναφορά προβλήματος" hide-footer>
      <b-overlay v-if="!isEducational" :show="submittingIssue" rounded="sm">
        <b-alert variant="warning" :show="true">Παρακαλώ χρησιμοποιείστε αυτή την φόρμα για αναφορά επιβεβαιωμένων προβλημάτων.
          Εάν έχετε απορίες, επικοινωνήστε μαζί μας μέσω του <strong>live chat</strong> για την άμεση εξυπηρέτησή σας. </b-alert>
        <b-form-group id="title-group" label="Τι αφορά το πρόβλημά σας; (ή η ιδέα σας)" label-for="title">

          <b-form-input id="title" v-model="issue" type="text" required placeholder="π.χ. Αντιγραφή διατροφικού πλάνου"></b-form-input>
        </b-form-group>
        <b-form-group id="input-notes" label="Περιγράψτε μας το πρόβλημά σας (ή την ιδέα σας)" label-for="notes">
          <b-form-textarea id="notes" v-model="description" type="textarea" placeholder="Περιγράψτε μας το πρόβλημά σας όσο πιο αναλυτικά γίνεται ώστε να μπορέσουμε να το λύσουμε/διορθώσουμε/προσθέσουμε!" rows="6"></b-form-textarea>
        </b-form-group>

        <b-form-group id="button-group" class="mt-4">
          <b-button type="submit" variant="primary" class="btn-block" @click="submitIssue"> Αποστολή αναφοράς </b-button>
        </b-form-group>

        <b-alert variant="danger" class="mt-3" :show="error !== null">{{error}}</b-alert>
      </b-overlay>
      <educational-unavailable v-else/>

    </b-modal>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xl-9">
          <b-button variant="link" class="report-problem-btn" @click="openSubmitIssueModal"><i class="icon-support"></i> Αναφορά προβλήματος</b-button>
          |
          <b-button variant="link" class="report-problem-btn" @click="$router.push('/library')">  Επιστημονική βιβλιοθήκη</b-button>
          |
          <b-button variant="link" class="report-problem-btn" @click="$router.push('/faq')">  Συχνές ερωτήσεις</b-button>
          |
          <b-button variant="link" class="report-problem-btn" @click="$router.push('/terms')">  Όροι χρήσης</b-button>
          |
          <a href="https://athlisis.convas.io/?status=Open" target="_blank" class="mt-1">  Ψηφοφορία ιδεών</a>
          |
          <a href="https://www.athlisis.com/changelog" target="_blank" class="mt-1">  Changelog</a>
          |
          <a href="https://www.youtube.com/playlist?list=PLy3yIxhvXRxWoepnrSmZh6e32RFjin6V6" target="_blank" class="mt-1">  Εκπαιδευτικά βίντεο</a>

        </div>
        <div class="col-12 col-xl-3">

          <div class="text-xl-right">
             2019-{{new Date().getFullYear()}} &copy; Athlisis.com</div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end Footer -->
</template>

<style lang="scss">

#footer{
  .report-problem-btn{
    padding:0;
  }
  .footer-links{
    button, a{
      cursor:pointer;
      padding:0!important;
    }
  }
}


</style>
