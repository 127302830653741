export const state = {
    id: null,
    dietPlan: null,
    dietPlanInfo: null,
    statistics: null,
    loadingDone: false
}

export const getters = {};

export const mutations = {

    CACHE_DP(state, dp){
        state.dietPlan = dp;
    },
    CACHE_DP_INFO(state, dp){
        state.dietPlanInfo = dp;
    },
    CACHE_STATS(state, statistics){
        state.statistics = statistics;
    },
    CACHE_DP_ID(state, id){
        state.id = id;
    },
    CACHE_LOADING_STATE(state, status){
        state.loadingDone = status;
    }
}

export const actions = {

    cacheDietPlanForPdf({state, commit}, dp){
        commit('CACHE_DP', dp);
        commit('CACHE_LOADING_STATE', true);
    },

    cacheDietPlanStatisticsForPdf({state, commit}, statistics){
        commit('CACHE_STATS', statistics);
    },

    cacheDietPlanId({state, commit}, id) {
        commit('CACHE_DP_ID', id);
    },

    cacheDietPlanInfo({state, commit}, dp){
        commit('CACHE_DP_INFO', dp);
    },
    resetLoadingState({state, commit}, status){
        // we reset state if the status === false which is initialization.
        commit('CACHE_LOADING_STATE', status);
    }
}

