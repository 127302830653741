<script>
import TopBar from '@components/top-bar'
import Footer from '@components/footer'
import OnlineStatus from "@components/global/OnlineStatus";
import CloudSidebar from "@views/cloud/CloudSidebar";

export default {
  components: { TopBar, Footer, OnlineStatus, CloudSidebar },
  data() {
    return {
      isMenuOpened: false,
      user: this.$store ? this.$store.state.auth.currentUser : {} || {},
    }
  },
  created: () => {
    document.body.classList.remove('authentication-bg');
    document.body.classList.remove('authentication-bg-pattern');
  },

  methods: {
    toggleMenu() {
      this.isMenuOpened = !this.isMenuOpened
    }
  },
}
</script>

<style lang="scss">
#cloud-home {
  min-height: 94vh;

  > div {
    padding: 30px;
  }

  padding-top: $topbar-height;

  .cloud-base-side-panel {
    max-height:90vh;
    padding-left:3em;
    background-color: $color_light_bg;
    //box-shadow: 10px 0 5px -2px #888;
  }

  .cloud-base-content {
    max-height:85vh;
    overflow-y:auto;
    //background-color:#fff;
  }
}
</style>
<template>
  <div>
    <header id="topnav">
      <TopBar :is-menu-opened="isMenuOpened"/>
    </header>
    <div v-if="!isEducational" id="cloud-home" class="row h-100">
      <cloud-sidebar/>

      <div class="col-12 col-md-9 cloud-base-content">
        <slot/>
      </div>
    </div>
    <educational-unavailable id="cloud-home" v-else/>
      <Footer />
      <OnlineStatus/>
  </div>
</template>
