import errors from "@src/lang/el/errors";
import success from "@src/lang/el/success";
import components from '@src/lang/el/components/index';
import diet_plan from "./diet_plan"
import routes from "@src/lang/el/routes";
import pages from "@src/lang/el/pages";
export default{

    errors,
    success,
    components,
    pages,
    diet_plan,
    routes,
    click: 'κάντε κλικ εδώ',
    select: 'Κάντε μια επιλογή',
    home: 'Αρχική σελίδα',
    today: 'Σήμερα',
    month:    'Μήνας',
    week:     'Εβδομάδα',
    day:      'Ημέρα',
    timeGrid: 'Ημέρα',
    beta: 'Δοκιμαστική έκδοση',
    save: 'Αποθήκευση',
    saveChanges: 'Αποθήκευση αλλαγών',
    create: 'Δημιουργία',
    delete: 'Διαγραφή',
    search: 'Αναζήτηση',
    searchPlaceholder: 'Αναζήτηση...',
    actions: 'Ενέργειες',
    verifyDeletion: {
        title: 'Θέλετε σίγουρα να προχωρήσετε σε διαγραφή;',
        message:'<p>Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, δεν θα μπορέσουμε με κανέναν τρόπο να επαναφέρουμε τα δεδομένα σας.' +
            '</p> <p><strong>Θέλετε σίγουρα να προχωρήσετε;</strong></p>',
        ok: 'Ναι, διαγραφή',
        cancel: 'Όχι, ακύρωση'
    },
    days:{
        monday: 'Δευτέρα',
        tuesday: 'Τρίτη',
        wednesday: 'Τετάρτη',
        thursday: 'Πέμπτη',
        friday: 'Παρασκευή',
        saturday: 'Σάββατο',
        sunday: 'Κυριακή'
    },
    date: 'Ημερομηνία',
    google_calendar_redirect: {
        title: 'Η σελίδα αυτή είναι μόνο για τον διατροφολόγο σας',
        text: 'Ο σύνδεσμος που ακολουθήσατε είναι η καρτέλα των στοιχείων σας και είναι μόνο προσβάσιμη από τον διατροφολόγο σας.' +
            'Εάν επιθυμείτε να χρησιμοποιήσετε το mobile app μας, παρακαλώ επικοινωνήστε με τον διατροφολόγο σας για οδηγίες!',
        dietitian: 'Είστε ο διατροφολόγος και δεν έχετε συνδεθεί;',
        button: 'Πατήστε εδώ για να συνδεθείτε'
    }
}
