<template>
  <div id="daily-fiber">
    <h5>Ημερήσια πρόσληψη διαιτητικών ινών</h5>

    <div class="row mt-4">
      <div class="col-md-12">
        <b-form-group label="Ημερήσια πρόσληψη ενέργειας" label-for="edit-kcal">
          <b-input-group append="kcal" >
            <b-form-input id="edit-kcal" v-model="kcal" type="text" placeholder="Ημερήσια πρόσληψη ενέργειας σε kcal"
                          @input="kcal = replaceCommaWithDot(kcal)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-12 text-right">
        <b-button variant='primary' @click="calculate">Υπολογισμός</b-button>
      </div>
      <div v-if="result" class="col-md-12 text-center mt-3">
        <h4>Συνίσταται πρόσληψη <u><strong>{{ result }}g</strong> διαιτητικών ινών</u>  ανά ημέρα.</h4>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      kcal: null,
      result: null
    }
  },
  methods:{
    calculate(){
      this.result = this.kcal / 1000 * 14;
    }
  }
}

</script>
