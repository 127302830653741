import axios from 'axios'
import {getSavedState, saveState} from "@utils/util";


export const state = {
    _sports: getSavedState('sports.sports'),
    lastUpdated: getSavedState('sports.lastUpdated')
};

export const getters = {

    sports: (state) => state._sports,

};

export const mutations = {

    CACHE_SPORTS(state, sports){
        state._sports = sports;
    },

    CACHE_SPORTS_TIME(state, time){
        state.lastUpdated = time;
    },


};

export const actions = {

    async init({state, dispatch}){
        // execute only if user is logged in
        if(localStorage.getItem('auth.token') !== 'null'){
            if(!state._sports) {
                await dispatch('fetchCacheSports');
            }else{
                await dispatch('refreshCacheSports');
            }
        }

    },

    async fetchCacheSports({ commit }){
        console.warn('Fetching sports...');

        return axios.get(process.env.VUE_APP_API + `/general/sports`).then(result => {
            commit('CACHE_SPORTS', result.data);
            commit('CACHE_SPORTS_TIME', new Date());
            saveState('sports.sports', result.data);
            saveState('sports.lastUpdated', state.lastUpdated);

        }).catch(e => {
            console.error('Sports fetching has failed!');
        });
    },

    refreshCacheSports({commit}){
        /**
         * @todo: add check in case localStorage is lost so that it refreshes state!
         */
        commit('CACHE_SPORTS', getSavedState('sports.sports'));
        commit('CACHE_SPORTS_TIME', getSavedState('sports.lastUpdated'))
    },


    reset({commit}){
        commit('CACHE_SPORTS', null);
        commit('CACHE_SPORTS_TIME', null);
    }
};