<template>

  <div id="educational-component" class="text-center">
    <img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="250"/>
    <div class="text-center">
      <h5 class="mt-3 text-uppercase font-weight-bold">ΜΗ ΔΙΑΘΕΣΙΜΗ ΛΕΙΤΟΥΡΓΙΑ</h5>
      <p>Δεν μπορείς να χρησιμοποιήσεις αυτή την λειτουργία με την εκπαιδευτική έκδοση του athlisis.</p>

      <p>
        Ήξερες ότι λόγω της σχολής σου μπορείς να αποκτήσεις το athlisis με την μοναδική έκπτωση 48% (και δυνατότητα 2 δόσεων) από
        <strong><del>2100€</del>
        </strong> μόνο <strong>1100€</strong>, χρησιμοποιώντας τον εκπτωτικό κωδικό
      </p>

      <h2 class="text-red mb-4" style="font-weight:900">EDUCATION1100</h2>
      <a href="https://www.athlisis.com" class="font-weight-bold" target="_blank">Επισκέψου το athlisis.com τώρα!</a>
    </div>
  </div>

</template>

<script>

export default{

}
</script>
