<template>
  <div id="pathisis">

    <div v-if="state ==='PATHISIS_LIST'" class="row">
      <div class="col-md-4 pathisis-sidebar">
        <ul style="padding-left:0;list-style-type:none;">
          <li v-for="category of categories" :key="category.id" :class="category.id === selectedCategory ? 'current-category' : ''" @click="switchCategory(category.id)">
            {{category.name}}
          </li>
        </ul>
      </div>
      <div class="col-md-8">

        <div v-for="item in pathisis" :key="item.id" class="pathisis-item-list-wrapper p-2 mt-2" @click="viewPathisis(item)">

          <div class="row align-items-center">
            <div class="col-md-8">
              <h4>{{item.title}}</h4>
            </div>
            <div class="col-md-4 text-right">
              <b-button variant="primary" size="sm" class="read-arrow"><i class="fe-arrow-right"></i></b-button>
            </div>
          </div>

        </div>

        <div v-if="pathisis.length === 0">
          Δεν υπάρχουν ακόμη παθήσεις στη συγκεκριμένη κατηγορία
        </div>

      </div>
    </div>
    <div v-if="state === 'PATHISIS_SINGLE_PAGE'" class="row">
      <div class="col-md-12">
        <b-button variant="light" @click="returnToList">Επιστροφή στη λίστα</b-button>
      </div>
      <div class="pathisis-content col-md-12 mt-3">

        <h4 class="pl-2">{{selectedPathisis.title}}</h4>
        <hr/>
        <b-tabs content-class="mt-1" nav-class="nav-tabs nav-bordered">
          <b-tab v-if="selectedPathisis.sustaseis_diatrofis" title="Συστάσεις διατροφής" active>
            <vue-markdown>{{selectedPathisis.sustaseis_diatrofis}}</vue-markdown>
          </b-tab>
          <b-tab v-if="selectedPathisis.odigies_diaitomenou" title="Οδηγίες προς διαιτώμενο">
            <div class="row">
              <div class="col-md-12 text-right">
                <b-button variant="light" @click="printInstructions"><i class="fe-printer"></i> Εκτύπωση</b-button>
              </div>
            </div>
            <vue-markdown>{{selectedPathisis.odigies_diaitomenou}}</vue-markdown>
            <div v-show="isPrinting" id="print-area" ref="print-area">
              <div v-show="isPrinting" class="print-intro row">
                <div class="col-md-6 col-2-3">
                  <strong > Ημερομηνία: </strong>{{ getDate() }}<br>
                  <span ><strong>Διατροφολόγος:</strong> {{user.firstName + ' ' + user.lastName}} </span>
                </div>
                <div class="col-md-6 col-1-3 text-right" >
                  <img :src="user.logo ? user.logo : 'https://edlservices.s3.eu-north-1.amazonaws.com/athlisis-icon-printing.png'" width="150" style="max-height:75px;width:auto;max-width:150px;" class="pr-3"/>
                </div>
              </div>
              <div class="article-title">Οδηγίες διαιτώμενου για την πάθηση: <strong>{{selectedPathisis.title}}</strong></div>

              <div class="note-body">
                <vue-markdown>{{selectedPathisis.odigies_diaitomenou}}</vue-markdown>
              </div>

            </div>
          </b-tab>
          <b-tab v-if="selectedPathisis.proteinomena_simpliromata" title="Προτεινόμενα συμπληρώματα">
            <vue-markdown>{{selectedPathisis.proteinomena_simpliromata}}</vue-markdown>
          </b-tab>
          <b-tab v-if="selectedPathisis.diagnwstika_ergaleia" title="Διαγνωστικά εργαλεία">
            <vue-markdown>{{selectedPathisis.diagnwstika_ergaleia}}</vue-markdown>
          </b-tab>
          <b-tab v-if="selectedPathisis.thewria_pathisis_gia_meleti" title="Θεωρία πάθησης για μελέτη">
            <vue-markdown>{{selectedPathisis.thewria_pathisis_gia_meleti}}</vue-markdown>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <div class="text-right m-2">
      <img src="@assets/images/pathisis.svg" width="175"/>
    </div>
  </div>

</template>

<style lang="scss">
#pathisis{
  .pathisis-sidebar{
    max-height:700px;
    overflow-y:scroll;
    li{
      cursor:pointer;
      padding:10px 20px;
      color:#000;
      &.current-category{
        background-color:#e7f6fd;
        //border-bottom:1px solid $color_primary;
      }
    }
  }
  .pathisis-item-list-wrapper{
    max-height:700px;
    overflow-y:scroll;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor:pointer;
    border-radius:12px;
    .read-arrow{
      border-radius:50%;
      padding:3px 7px;
    }
  }
  .pathisis-content{

    .tab-content{
      max-height:700px;
      overflow-y:scroll;
      padding:20px;
      img{
        max-width:100%;
      }
    }
  }
}
</style>


<script>

import axios from "axios";
import VueMarkdown from 'vue-markdown'
import {mapState} from "vuex";


export default{

  components: {VueMarkdown},
  watch:{
    selectedCategory(newVal){
      this.fetchPathisisList(newVal);
    }
  },
  computed:{
    ...mapState({
      user: state => state.auth.currentUser
    }),
  },
  data(){
    return {
      state: 'PATHISIS_LIST',
      categories: sessionStorage.getItem('pathisis-categories') ? JSON.parse(sessionStorage.getItem('pathisis-categories')) : [],
      pathisis: [],
      selectedCategory: sessionStorage.getItem('pathisis-selected-category') || null,
      selectedPathisis: null,
      isPrinting: false
    }
  },

  mounted(){
    this.checkIfUpdateRequired();
      this.fetchPathisisCategories();
      this.switchCategory(this.selectedCategory);

  },

  methods:{

    fetchPathisisCategories(){
      if(!sessionStorage.getItem('pathisis-categories')) {
        let instance = axios.create();

        instance.get('https://cms.athlisis.com/pathisis-categories', {
          transformRequest: (data, headers) => {
            delete headers.common['x-dietitian-token'];
            delete headers.common['x-access-token'];
          }
        }).then(res => {
          this.categories = res.data;
          this.switchCategory(this.categories[0].id);
          sessionStorage.setItem('pathisis-categories', JSON.stringify(res.data));
        });
      }else{
        this.switchCategory(this.categories[0].id);
      }
    },

    fetchPathisisList(category){
      if(!sessionStorage.getItem('pathisis-' + category)) {
        let instance = axios.create();

        instance.get('https://cms.athlisis.com/pathises', {
          params: {'pathisis_category.id':  parseInt(category)}, transformRequest: (data, headers) => {
            delete headers.common['x-dietitian-token'];
            delete headers.common['x-access-token'];
          }
        }).then(res => {
          sessionStorage.setItem('pathisis-' + category, JSON.stringify(res.data));
          this.loadPathisisList();
        });
      }else{
        this.loadPathisisList();
      }
    },
    switchCategory(id){
      this.selectedCategory = id;
      sessionStorage.setItem('pathisis-selected-category', this.selectedCategory);
    },
    loadPathisisList(){
      this.pathisis = JSON.parse(sessionStorage.getItem('pathisis-' + this.selectedCategory));
    },
    returnToList(){
      this.state = 'PATHISIS_LIST'
    },

    viewPathisis(pathisis){
      for(let key of Object.keys(pathisis)){
        if(typeof pathisis[key] === 'string'){
          pathisis[key] = pathisis[key].replace(/&lt;/g, '<')
          pathisis[key] = pathisis[key].replace(/&gt;/g, '>')
          pathisis[key] = pathisis[key].replace(/&amp;/g, '&')
          pathisis[key] = pathisis[key].replace(/&quot;/g, '"')
          pathisis[key] = pathisis[key].replace(/&apos;/g, "'")
        }
      }
      this.selectedPathisis = {...pathisis};
      this.state = 'PATHISIS_SINGLE_PAGE'
    },
    checkIfUpdateRequired(){
      if(sessionStorage.getItem('lastUpdate')){
        let shouldUpdateDate = new Date(sessionStorage.getItem('lastUpdate'));
        let currentDate = new Date();
        shouldUpdateDate.setDate(shouldUpdateDate.getDate() + 1);
        if(currentDate > shouldUpdateDate){
          sessionStorage.clear();
        }
      }else{
        sessionStorage.setItem('lastUpdate', new Date());
      }
    },
    printInstructions(){
      this.isPrinting = true;
      let divToPrint = this.$refs['print-area'].outerHTML;
      this.isPrinting = false;
      // remove empty paragraphs.
      divToPrint = divToPrint.replaceAll("<p><br></p>", "");
      divToPrint = divToPrint.replaceAll('display: none', "");

      let newWin = window.open("", "","toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=900,height=900, margins:default");
      newWin.document.write('<html><head><title>Εκτύπωση οδηγιών διαιτώμενου: ' + this.selectedPathisis.title + '</title>');
      newWin.document.write('<link rel="stylesheet" type="text/css" href="https://edlservices.s3.eu-north-1.amazonaws.com/athlisis-print-v2.css">');
      newWin.document.write('<style type="text/css">@page{margin:.2in;} .print-intro{display:flex!important;padding-bottom:0px;}.article-category{display:none}' +
          '.article-title,.article-footer{background-color:#f3f3f3;padding:20px;text-align:center}img{max-width:100%!important;max-height:450px;}</style>');
      newWin.document.write('</head><body>');
      newWin.document.write(divToPrint);
      newWin.document.write('</body></html>');
      newWin.document.close();
      newWin.focus();
      newWin.print();
      newWin.onfocus = function () { setTimeout(function () { newWin.close(); }, 2000); }
    }

  }

}
</script>
