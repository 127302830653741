<template>
  <div class="col-md-12 border-1 p-1">

    <div class="row">
      <div class="col-md-6">
        <b-form-group id="input-firstName" label="Όνομα*" label-for="firstName" >
          <b-form-input id="firstName" v-model="newClient.firstName" type="text" placeholder="Εισάγετε το όνομα του πελάτη" required></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group id="input-lastName" label="Επώνυμο*" label-for="lastName">
          <b-form-input id="lastName" v-model="newClient.lastName" type="text" placeholder="Εισάγετε το επώνυμο του πελάτη" required></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <b-form-group id="input-birthDate" label="Ημερομηνία Γέννησης*" label-for="birthDate">
          <date-picker v-model="newClient.birthDate"  :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY" placeholder="Ημέρα/μήνας/έτος"></date-picker>
        </b-form-group>
      </div>

      <div class="col-md-6">
        <b-form-group id="input-gender" label="Φύλο*" label-for="gender">
          <b-form-select v-model="newClient.sex" required>
            <option value="null">Επιλέξτε φύλο: </option>
            <option value="M">Άρρεν</option>
            <option value="F">Θήλυ</option>
          </b-form-select>
        </b-form-group>
      </div>

    </div>
    <div class="row">
      <div class="col-md-6">
        <b-form-group id="input-email" label="E-mail" label-for="email" >
          <b-form-input id="email" v-model="newClient.email" type="text"
                        @change="newClient.email = newClient.email.replaceAll(' ','').toLowerCase()"
                        placeholder="Προαιρετικό πεδίο"></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group id="input-mobileNum" label="Κινητό τηλέφωνο" label-for="mobileNum">
          <b-form-input id="mobileNum" v-model="newClient.mobileNum" type="text" placeholder="Προαιρετικό πεδίο"></b-form-input>
        </b-form-group>
      </div>

      <div class="col-md-12 mb-2">
        <label>Κόστος συνεδρίας:</label>
        <b-input-group append="€" >
          <b-form-input type="number" step="1" v-model="newClient.sessionCost" placeholder="(π.χ. 50)"/>
        </b-input-group>
      </div>

    </div>
    <div class="text-right">
      <b-button size="md" variant="primary" @click="saveNewCustomer" >Δημιουργία πελάτη</b-button>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import DatePicker from 'vue2-datepicker'

export default{

  components:{DatePicker},
  data(){
    return{
      newClient: this.getBaseAthlete,
      error: null
    }
  },
  created(){
    this.newClient = this.getBaseAthlete
  },
  computed:{
    getBaseAthlete(){
      return {
        firstName: '',
        lastName: '',
        sex: null,
        birthDate: '',
        email: '',
        mobileNum: '',
        sessionCost: 0,
        athleteShortQuestionnaire: {
          q1: new Date(), q2: null, q3: null, q4: null, q5: null, q6: null, q7: null, q8 :null, q9: null, q10     : null, q11     : null, q12     : null, q13     : null, q13_1     : null,
          q14     : null, q14_1     : null, q15     : null, q15_1     : null, q16     : null, q16_1     : null, q17     : null, q17_1     : null, q18     : null, q18_1     : null, q19     : null, q19_1     : null, q20     : null, q20_1     : null,
          q21     : null, q21_1     : null, q22     : null, q22_1     : null, q23     : null, q23_1     : null, q24     : null, q24_1     : null, q25     : null, q25_1     : null, q26     : null, q26_1     : null,
          q27     : null, q28    : null, q29     : null, q30     : null, q31     : null, q32     : null, q33     : null, q34     : null, q35     : null,},
        measurements: {
          weight: null,
          height: null,
          bmi: null,
          fatPercentage: null
        },
      }
    }
  },
  methods: {

    saveNewCustomer() {
      this.error = null;
      if (!this.newClient.firstName || !this.newClient.lastName || !this.newClient.sex || !this.newClient.birthDate) {
        this.error = 'Παρακαλώ εισάγετε όλα τα υποχρεωτικά στοιχεία για να δημιουργήσετε τον πελάτη.'
        return;
      }

      this.newClient.birthDate = new Date(this.newClient.birthDate).setHours(12);

      axios.post(`${process.env.VUE_APP_API}/athlete/`, this.newClient).then(result => {
        this.$emit('clientCreated');
        this.createNewClient = false;
        this.newClient = this.getBaseAthlete
        this.$notify({group: 'athlisis-notifications', type: 'success', title: 'Επιτυχία!', text: 'Ο πελάτης δημιουργήθηκε επιτυχώς!'});
      }).catch(e => {
        this.isCreatingAthlete = false;
        this.$notify({group: 'athlisis-notifications', type: 'error', title: 'Αποτυχία!', text: 'Η δημιουργία του πελάτη απέτυχε!!'});
      });


    },
  }

}
</script>
