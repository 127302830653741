<script>
  import { authComputed } from '@state/helpers'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import axios from 'axios'
  import {mapState} from "vuex";
  import toolsBase from '@components/tools/tools-base'
  import NewCustomerModal from "@components/global/NewCustomerModal";
  import pathisisBase from "@components/pathisis/pathisis-base"
  import vClickOutside from 'v-click-outside'
  export default {

      components: { VuePerfectScrollbar, toolsBase, NewCustomerModal, pathisisBase },
      directives: {
        clickOutside: vClickOutside.directive
      },
      props: {
          isMenuOpened: {
            type: Boolean,
            required: false,
            default: false,
          },
      },

      data() {
          return {
            notifFetched: false,
            fetchingResults: false,
            searchKeyword: '',
            timer: null,
            athletes: [],
            showAthleteList: false,
            menu:[
              {
                name: 'Πίνακας ελέγχου',
                icon: require('@assets/icons/dashboard-icon.svg'),
                url: '/'
              },
              {
                name: 'Πελάτες',
                icon: require('@assets/icons/clients-icon.svg'),
                url: '/athletes',
                hasSubmenu: true,
                subMenu: [
                  {
                    name: 'Όλοι οι πελάτες',
                    icon: 'fe-list',
                    url: '/athletes'
                  },
                  {
                    name: 'Στατιστικά στοιχεία',
                    icon: 'fe-pie-chart',
                    url: '/athletes/statistics'
                  },
                  {
                    name: 'Προσθήκη νέου πελάτη',
                    icon: 'fe-plus-circle',
                    url: '/athletes/add'
                  },
                ]
              },
              {
                name: 'Τρόφιμα',
                icon: require('@assets/icons/foods-icon.svg'),
                url: '/foods'
              },
              {
                name: 'Διατροφικά πλάνα',
                icon: require('@assets/icons/dp-icon.svg'),
                url: '/diet-plans',
                hasSubmenu: true,
                subMenu: [
                  {
                    name: 'Όλα τα πλάνα',
                    icon: 'fe-list',
                    url: '/diet-plans'
                  },
                  {
                    name: 'Πρότυπα γεύματα',
                    icon: 'fas fa-swatchbook',
                    url: '/meals'
                  },
                  {
                    name: 'Στατιστικά στοιχεία',
                    icon: 'fe-pie-chart',
                    url: '/diet-plans/statistics'
                  },
                  {
                    name: 'Πρότυπα εκτύπωσης',
                    icon: 'icon-printer',
                    url: '/user/print-templates'
                  },
                  {
                    name: 'Πρότυπα σημειώσεων',
                    icon: 'icon-notebook',
                    url: '/diet-plans/notes'
                  },
                  {
                    name: 'Συνταγές',
                    icon: 'icon-book-open',
                    url: '/recipes'
                  }
                ]
              },
            ],
          }
      },

      computed: {
        ...authComputed,
        ...mapState({
          user: state => state.auth.currentUser,
          notifications: state => state.notifications.notifications,
          notificationCount: state => state.notifications.notificationsCount,
          unreadChats: state => state.chat.unreadChats
        }),
        getAthletes(){
          return this.athletes
        }
      },

      created(){
        this.$store.dispatch('notifications/refreshCacheNotifications');
      },
      watch:{
          searchKeyword(val){
            this.fetchAthletes();
          }
      },

      methods: {

        clientCreated(){
          this.$refs['create-client'].hide();
        },
          openToolsModal(){
            this.$refs['tools-modal'].show();
          },
        openPathisisModal(){
          this.$refs['pathisis-modal'].show();
        },
        openCloudModal(){
          this.$router.push({name: 'cloud'});
        },
        openChat(){
          this.$router.push({name: 'chat'});
        },
          toggleMenu() {
              this.$parent.toggleMenu()
          },

          markAllAsRead(){
              axios.put(`${process.env.VUE_APP_API}/notifications/markAllAsRead`).then(result => {
                  this.$store.dispatch('notifications/cacheNotifications');
                  this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Οι ειδοποιήσεις επισημάνθηκαν ως αναγνωσμένες!'});
              }).catch(e =>{
                  this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
          },
          fetchAthletes() {

            if(this.searchKeyword === "" || !this.searchKeyword){
              this.searchKeyword = null;
              this.athletes = [];
              this.hideSearchResults();
              return;
            }

            const params = {
              limit: 10,
              offset: 0,
              search: this.searchKeyword,
            };

            this.$axios.get(`/athlete/`, {params: params}).then( result =>{
              this.fetchingResults = false;
              this.athletes = result.data.rows;
              this.showSearchResults();

            }).catch(e=>{
              this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            });


        },
        hideSearchResults(event){
            this.fetchingResults = false;
            if(event && event.target && event.target.id === 'search-athletes'){
              return;
            }
            this.showAthleteList = false;
        },

        showSearchResults() {
          if (this.searchKeyword === "" || this.searchKeyword == null) return this.hideSearchResults();
          this.showAthleteList = true;
        },
        redirectTo(route){
          this.hideSearchResults();
          this.$router.push(route);
        }

      },
}
</script>

<template>
  <!-- Topbar Start -->
  <div id="topbar" class="navbar-custom">
    <div class="container-fluid">
      <ul class="list-unstyled topnav-menu float-right mb-0">
        <li class="dropdown notification-list">
          <a class="navbar-toggle nav-link" :class="{ 'open': isMenuOpened }" @click="toggleMenu">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </li>

        <li class="d-none d-sm-block b-nav-dropdown dropdown">
          <div class="app-search">
            <div class="app-search-box">
              <div class="input-group">
                <div class="input-group-prepend">
                  <button class="btn">
                    <img v-if="!fetchingResults" src="@assets/icons/search-icon.svg"/>
                    <img v-else src="@assets/images/loading.svg" height="20px" alt="loading-icon"/></button>
                </div>
                <b-form-input id="search-athletes" v-model="searchKeyword" type="text" class="form-control" placeholder="Αναζήτηση..."
                              debounce="700"
                              @input="fetchingResults = true"
                              @focus="showSearchResults"/>


              </div>
            </div>
          </div>
          <ul class="dropdown-menu dropdown-menu-right" v-click-outside="hideSearchResults" :class="showAthleteList ? 'show' :''" style="min-width:300px;">
              <b-dropdown-text class="p-0">
<!--                <li role="presentation" class="dropdown-item noti-title">-->
                  <span class="b-dropdown-text"><h5 class="m-0 pt-2 pb-2 ">Αποτελέσματα αναζήτησης</h5></span>
<!--                </li>-->
                <div v-if="fetchingResults">
                  <img src="@assets/images/loading.svg" height="50px" alt="loading-icon"/>
                </div>
                <div v-else class="ps-container ps">
                  <div v-if="showAthleteList && athletes && athletes.length > 0" class="noti-scroll">
                    <!-- items-->
                    <div v-for="athlete in getAthletes" :key="athlete.id" class="dropdown-item notify-item text-link" @click="redirectTo(`/athletes/${athlete.id}`)">

                        <p class="notify-details">{{athlete.firstName}} {{athlete.lastName}}<br><small class="text-muted">Τηλέφωνο: {{ athlete.mobileNum }}</small></p>

                    </div>

                  </div>
                </div>
                <a v-if="notifications && notifications.length > 0" class="dropdown-item text-center text-primary notify-item notify-all text-link"
                   @click="$router.push({ path: '/athletes', query: { search: searchKeyword } } )">Προβολή όλων <i class="fe-arrow-right"></i></a>

              </b-dropdown-text>
          </ul>
        </li>

        <li class="nav-link dropdown-toggle notification-list calendar-btn" :class="$route.name && $route.name.includes('cloud') ? 'show' : ''"   @click="openCloudModal">
          <div>
            <img src="@assets/icons/cloud-icon-1.svg" class="topbar-right-icon mr-xl-1"/> <span class="hide-mobile">aCloud</span>
          </div>

<!--          <div  class="topbar-right-icon">Cloud</div>-->
        </li>
        <li class="nav-link dropdown-toggle notification-list calendar-btn chat-btn" :class=" $route.name && $route.name.includes('chat') ? 'show' : ''"   @click="openChat">
          <div>
            <img src="@assets/icons/chat.svg" width="19"/>

          </div>
          <b-badge v-if="unreadChats > 0" variant="danger" pill class="rounded-circle noti-icon-badge">{{unreadChats}}</b-badge>
        </li>
        <li  class="nav-link dropdown-toggle notification-list calendar-btn"
             :class="$refs['tools-modal'] && $refs['tools-modal'].$data.isShow ? 'show' : ''"  @click="openToolsModal">
          <img src="@assets/icons/settings-icon.svg" class="topbar-right-icon"/>
        </li>



        <li  class="nav-link dropdown-toggle notification-list calendar-btn pathisis-btn"
             :class="$refs['pathisis-modal'] && $refs['pathisis-modal'].$data.isShow ? 'show' : ''"  @click="openPathisisModal">
          <img src="@assets/icons/pathisis-icon.svg" class="topbar-right-icon"/>
        </li>

        <li  class="nav-link dropdown-toggle notification-list calendar-btn "
             :class=" $route.name === 'calendar' ? 'show' : ''"  @click="$router.push( { name: 'calendar' } )">
          <img src="@assets/icons/calendar-icon.svg" class="topbar-right-icon"/>
        </li>
      <!-- notification list -->
        <b-nav-item-dropdown right class="notification-list hide-mobile">
          <template slot="button-content" class="nav-link dropdown-toggle">
            <img src="@assets/icons/notification-icon.svg" class="topbar-right-icon"/>
            <span v-if="notificationCount > 0" class="badge badge-danger rounded-circle noti-icon-badge">{{notificationCount}}</span>
          </template>

          <b-dropdown-text href="#" class="dropdown-item noti-title">
            <h5 class="m-0"><span v-if="notificationCount > 0" class="float-right"><a class="text-dark text-link" @click="markAllAsRead"><small>Σήμανση ως αναγνωσμένα</small></a></span>
              Ενημερώσεις
            </h5>

          </b-dropdown-text>

          <b-dropdown-text href="#" class="p-0" style="min-width:300px;">
            <VuePerfectScrollbar class="noti-scroll">
              <div v-if="notifications && notifications.length < 1" class="text-center" style="margin-top:-30px;">
                <img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="100"/>
                <div class="text-center">
                  <h5 class="mt-3 text-uppercase font-weight-bold">ΔΕΝ ΥΠΑΡΧΟΥΝ ΕΝΗΜΕΡΩΣΕΙΣ</h5>
                </div>
              </div>
              <!-- items-->
              <a v-for="nf of notifications" :key="nf.id" class="dropdown-item notify-item text-link" @click="$router.push({ name: 'user-notifications' })">
                <div class="notify-icon bg-primary"><i class="icon-bell"></i></div><b-badge v-if="!nf.isRead" variant="danger">Μη αναγνωσμένο</b-badge>
                <p class="notify-details">{{nf.title}}<small class="text-muted">Ημερομηνία: {{ nf.updatedAt }}</small></p>
              </a>

            </VuePerfectScrollbar>

            <a v-if="notifications && notifications.length > 0" class="dropdown-item text-center text-primary notify-item notify-all text-link"
               @click="$router.push({ name: 'user-notifications' })">Προβολή όλων <i class="fe-arrow-right"></i></a>

          </b-dropdown-text>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right class="notification-list" menu-class="profile-dropdown">
          <template slot="button-content">
            <div class="nav-user mr-0">
              <img v-if="user" class="rounded-circle" :src="user.image ? user.image : 'https://www.gravatar.com/avatar/1db7993cb4a4a74ce58f4a6926cec867?s=80&d=mp&r=g'"/>

              <span class="pro-user-name ml-1">
                <span class="icon-arrow-down " style="font-size:8px;color:black"></span>
              </span>
            </div>
          </template>

          <b-dropdown-item to="/user">
              <i class="fe-user"></i><span>Λογαριασμός</span>
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item to="/logout">
            <i class="fe-log-out mr-1"></i><span>Αποσύνδεση</span>
          </b-dropdown-item>

        </b-nav-item-dropdown>

      </ul>

      <!-- LOGO -->
      <div class="logo-box">
        <router-link to="/" class="logo text-center">
          <span class="logo-lg">
            <img src="@assets/images/athlisis-logo.svg" alt height="28"/>
          </span>
          <span class="logo-sm">
            <img src="@assets/images/athlisis-logo-sm.svg" alt height="22"/>
          </span>
        </router-link>
      </div>
      <b-collapse id="navigation" v-model="isMenuOpened" style="padding-top:.3rem">
        <!-- Navigation Menu-->
        <ul class="navigation-menu">

          <li v-for="item in menu" :key="item.name" :class="item.hasSubmenu ? 'has-submenu' : ''">
            <router-link tag="a" :to="item.url">
              <img v-if="item.icon && item.icon.includes('/img/')" class="menu-img" :src="item.icon"/>
              <i v-else :class="item.icon"></i>
              <span class="hide-tablet">{{ item.name }}</span>
            </router-link>
            <ul v-if="item.hasSubmenu" class="submenu megamenu">
              <li>
                <ul>
                  <li v-for="sub in item.subMenu" :key="'sub-'+sub.name">
                    <router-link tag="a" :to="sub.url" class="side-nav-link-ref">
                      <i :class="sub.icon"></i>
                      {{ sub.name }}</router-link>
                  </li>
                  <li v-if="item.url === '/athletes'">
                    <a @click="$refs['create-client'].show()" style="cursor:pointer"><i class="fe-plus-square"></i> Γρήγορη προσθήκη πελάτη</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li v-if="user && user.isAdmin">
            <router-link tag="a" to="/admin/users" class="side-nav-link-ref admin-foods-link bg-light"><i class="fe-settings"></i>Admin</router-link>
          </li>
        </ul>
        <!-- End navigation menu -->

        <div class="clearfix"></div>
      </b-collapse>
    </div>

    <b-modal ref="tools-modal" id="tools-modal" title="Επιστημονικά Εργαλεία" size="xl" hide-footer>
      <tools-base/>
    </b-modal>

    <b-modal static ref="pathisis-modal" id="pathisis-modal" title="Pathisis" size="xl" hide-footer>
      <pathisis-base/>
    </b-modal>

    <b-modal ref="create-client" id="create-client" title="Γρήγορη δημιουργία πελάτη" size="md" hide-footer>
      <NewCustomerModal @clientCreated="clientCreated"/>
    </b-modal>
  </div>
  <!-- end Topbar -->
</template>

<style lang="scss">
  #topbar{
    .tools-dropdown{
      width:270px;
    }
    .calendar-btn{
      position:relative;
      cursor:pointer;
      &.show{
      background-color: $color_light_bg
      }
    }
  }
  .pathisis-btn{
    @media(max-width:767px){
      display:none!important;
    }
  }
  .hide-mobile{
    @media(max-width:1024px){
      display:none;
    }
  }
  .hide-tablet{
    @media(max-width:1350px) and (min-width:920px){
    display:none;
  }
  }
    .noti-scroll {
      height: 220px;
    }
    .ps > .ps__scrollbar-y-rail {
      width: 8px !important;
      background-color: transparent !important;
    }
    .ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
    .ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
    .ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
    .ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
      width: 6px !important;
    }
    .button-menu-mobile {
      outline: none !important;
    }
  .text-link{
    cursor:pointer;
  }
  .noti-title{
    width:325px;
  }
</style>
