export default{


    methods:{
        getMealName(dietPlan, mealType){
            if(dietPlan.dietPlanMealOptions.mealNames[mealType]){
                return dietPlan.dietPlanMealOptions.mealNames[mealType];
            }
            return this.$t('diet_plan.meals.'+mealType);
        },
        getValidMealsOrder(dietPlan){

            let mealOptions = dietPlan.dietPlanMealOptions;

            return mealOptions.mealsOrder.filter(x => x < mealOptions.totalMeals);
        },

    }
}