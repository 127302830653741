<template>

  <div id="food-preview">

    <b-tabs content-class="mt-1" nav-class="nav-tabs nav-bordered">
      <b-tab title="Τρόφιμο">
        <div class="row">
          <div class="col-md-6">
            <b-form-group id="edit-name" label="Όνομα τροφίμου" label-for="edit-name">
              <b-input-group>
                <b-form-input id="edit-name" v-model="food.name" type="text" disabled ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group id="edit-kcal" label="Ενέργεια" label-for="edit-kcal">
              <b-input-group append="kcal" >
                <b-form-input id="edit-kcal" v-model="food.kcal" type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b-form-group id="edit-carbs" label="Υδατάνθρακες" label-for="edit-carbs">
              <b-input-group append="g" >
                <b-form-input id="edit-carbs" v-model="food.carbohydrates" type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group id="edit-proteins" label="Πρωτεϊνες" label-for="edit-proteins">
              <b-input-group append="g" >
                <b-form-input id="edit-proteins" v-model="food.proteins" type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group id="edit-fats" label="Λίπη" label-for="edit-fats">
              <b-input-group append="g" >
                <b-form-input id="edit-fats" v-model="food.fats" type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group id="edit-quantity" label="Ποσότητα" label-for="edit-quantity">
              <b-input-group>
                <b-form-input id="edit-quantity" v-model="food.quantity" type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group id="edit-measure" label="Μονάδα μέτρησης" label-for="edit-measure">
              <label class="select-label">
                <select v-model="food.measurementUnit" class="custom-select" disabled>
                  <option v-for="item in foodMeasures" :key="item.id" :value="item.id">{{ item.name }}</option>
                </select>
              </label>
            </b-form-group>
          </div>
        </div>
        <b-form-group label="Επιλογή τύπου τροφίμου:">
          <div class="row">
            <div class="col-md-4">
              <b-form-checkbox id="isPrwino" v-model="food.foodMealCategory.isBreakfast" name="isBreakfast" disabled>Πρωινό</b-form-checkbox>
            </div>
            <div class="col-md-4">
              <b-form-checkbox id="isDekatiano" v-model="food.foodMealCategory.isDekatiano" name="isDekatiano"disabled >Δεκατιανό</b-form-checkbox>
            </div>
            <div class="col-md-4">
              <b-form-checkbox id="isMesimeriano" v-model="food.foodMealCategory.isMesimeriano" name="isMesimeriano"disabled >Μεσημεριανό</b-form-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <b-form-checkbox id="isApogeumatino" v-model="food.foodMealCategory.isApogeumatino" name="isApogeumatino"disabled >Απογευματινό</b-form-checkbox>
            </div>
            <div class="col-md-4">
              <b-form-checkbox id="isVradino" v-model="food.foodMealCategory.isVradino" name="isVradino"disabled >Βραδινό</b-form-checkbox>
            </div>
          </div>
        </b-form-group>
        <b-form-group label="Επιλογή ιδιοτήτων:">
          <div class="row">
            <div class="col-md-4">
              <b-form-checkbox id="isVegan" v-model="food.isVegan" name="isVegan"disabled >Είναι vegan;</b-form-checkbox>
            </div>
            <div class="col-md-4">
              <b-form-checkbox id="isFasting" v-model="food.isFasting" name="isFasting"disabled >Είναι νηστίσιμο;</b-form-checkbox>
            </div>
          </div>
        </b-form-group>
        <b-button variant="primary" v-if="food.microNutrients && Object.keys(food.microNutrients).length > 0" v-b-toggle.collapse-3 class="m-1">Μικροθρεπτικά</b-button>
        <b-collapse  v-if="food.microNutrients && Object.keys(food.microNutrients).length > 0" id="collapse-3" class="mb-2">
          <div class="row">
            <div v-for="micronutrient of microNutrientFields" :key="micronutrient" class="col-md-4">
              <label>{{microNutrientTranslation[micronutrient]}}
                <b-form-input v-model="food.microNutrients[micronutrient]" disabled/></label>
            </div>
          </div>
        </b-collapse>
      </b-tab>
      <b-tab title="Συζήτηση / σχόλια">

      </b-tab>
    </b-tabs>

    <div class="card-body">

    </div>

  </div>
</template>


<script>

export default{
  props:{
    food: Object
  },
}
</script>
