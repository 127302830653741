<template>
  <div>
    <b-alert v-if="showErrorNotice && ($route.name !== 'pay-transaction')"  class="mt-2" show variant="danger">Η συνδρομή σας έχει λήξει.
      <b-button variant="link" style="padding:0;margin:0;" @click="$router.push({ name: 'user-subscription' })"><strong>Ανανεώστε την τώρα κάνοντας κλικ εδώ</strong></b-button>
    </b-alert>
    <b-alert v-if="showWarningNotice && ($route.name !== 'pay-transaction')" class="mt-2" show variant="warning">Η συνδρομή σας λήγει σε {{ difference}} {{ difference === 1 ? 'ημέρα' : 'ημέρες' }}.
      <b-button variant="link" style="padding:0;margin:0;" @click="$router.push({ name: 'user-subscription' })"><strong>Ανανεώστε την τώρα κάνοντας κλικ εδώ</strong></b-button></b-alert>
  </div>
</template>

<script>

import {mapState} from "vuex";
import moment from "moment";

export default{

  computed:{
    ...mapState({
      user: state => state.auth.currentUser
    })
  },
  data(){
    return {
      showWarningNotice: false,
      showErrorNotice: false,
      difference: null
    }
  },
  created(){
    this.calculateDaysUntilSubscriptionEnds();
  },
  methods:{
    calculateDaysUntilSubscriptionEnds(){

      if(!this.user || !this.user.subscriptionDue) return;

      let dateFrom = moment(new Date());
      let dateTo = moment(new Date(this.user.subscriptionDue.substr(0,10)));

      let difference = dateTo.diff(dateFrom, 'days');

      this.difference = difference;

      if(difference > 0 && difference < 7){
        this.showWarningNotice = true;
      }

      if(difference <= 0){
        this.showErrorNotice = true;
      }

      if(difference > 7 ){
        this.showWarningNotice = false;
        this.showErrorNotice = false;
      }

    },
  }

}
</script>