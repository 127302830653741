import { render, staticRenderFns } from "./EducationalComponent.vue?vue&type=template&id=d050e968&"
import script from "./EducationalComponent.vue?vue&type=script&lang=js&"
export * from "./EducationalComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_f3ovp5syquuyqzyjytk6i7vpxq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports