<template>
  <div class="col-12 mb-4">
    <div class="pagination pagination-rounded mt-3 justify-content-end">
      <!-- pagination -->
      <b-pagination v-if="api.count > 0" v-model="api.page" :total-rows="api.count" :per-page="api.query.limit" @input="$emit('fetchData')"></b-pagination>
    </div>
  </div>
</template>

<script>
export default{

  props:{
    api: Object
  }
}
</script>