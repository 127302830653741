<template>
  <div id="kids-bmi" >

    <h5>Υπολογισμός λίπους με μεζούρες</h5>
    <span class="text-muted">Γρήγορος υπολογισμός ποσοστού (%) λίπους με μεζούρες </span>

    <div class="row mt-2">
      <div class="col-md-6">
        <b-form-group label="Φύλο:" label-for="edit-gender">
          <b-form-select v-model="sex" required>
            <option value="1">Άρρεν</option>
            <option value="0">Θήλυ</option>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="Ύψος:" label-for="edit-height">
          <b-input-group append="cm">
            <b-form-input id="edit-height" v-model="height" type="text" placeholder="Εισάγετε το ύψος σε εκατοστά"
                          @input="height = replaceCommaWithDot(height)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="Περιφέρεια Μέσης σε εκατοστά:" label-for="edit-pm">
          <b-input-group append="cm">
            <b-form-input id="edit-height" v-model="pm" type="text" placeholder="Εισάγετε την περιφέρεια μέσης σε εκατοστά"
                          @input="pm = replaceCommaWithDot(pm)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="Περιφέρεια Ισχύων σε εκατοστά:" label-for="edit-pi">
          <b-input-group append="cm">
            <b-form-input id="edit-height" v-model="pi" type="text" placeholder="Εισάγετε την περιφέρεια ισχύων σε εκατοστά"
                          @input="pi = replaceCommaWithDot(pi)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="Περιφέρεια Πήχη σε εκατοστά:" label-for="edit-pp">
          <b-input-group append="cm">
            <b-form-input id="edit-height" v-model="pp" type="text" placeholder="Εισάγετε την περιφέρεια πήχη σε εκατοστά"
                          @input="pp = replaceCommaWithDot(pp)"></b-form-input>
          </b-input-group>
        </b-form-group>

      </div>
      <div class="col-md-12 text-right">
        <b-button variant='primary' @click="calculate">Υπολογισμός λίπους</b-button>
      </div>
      <div v-if="result" class="col-md-12 text-center mt-3">
        Ποσοστό λιπώδους μάζας: <h4>{{result.toFixed(1)}} %</h4>
      </div>
    </div>
    <div class="row mt-4">
      Πηγή: KANELLAKIS, S., SKOUFAS, E., KHUDOKONENKO, V., APOSTOLIDOU, E., GERAKITI, L., ANDRIOTI, M. C., BOUNTOUVI, E. & MANIOS, Y. 2017.
      Development and validation of two equations based on anthropometry, estimating body fat for the Greek adult population. Obesity (Silver Spring), 25, 408-416.
    </div>
  </div>
</template>

<script>
export default{

  data(){
    return {
      sex: '1',
      height: null,
      pm: null,
      pi: null,
      pp: null,
      bmi: null,
      result: null
    }
  },

  methods:{
    calculate(){
      this.result = (-1 * 0.615) - (10.948 * (this.sex === '1' ? 1 : 0)) + (0.321*this.pm) + (0.502*this.pi) - (0.39 * this.pp) - (19.768 * this.height/100);
    }
  }
}
</script>
