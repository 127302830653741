<template>

  <div id="food-preview">

    <b-tabs content-class="mt-1" nav-class="nav-tabs nav-bordered">
      <b-tab title="Συνταγή">
        <b-card >
          <div class="row">
            <div class="col-12 col-md-6">
              <img :src="recipe.image" class="rounded-image"/>

              <div v-html="recipe.execution" class="mt-2"/>
            </div>
            <div class="col-12 col-md-6">
              <b-card class="w-auto">
                <b-card-title>Υλικά</b-card-title>
                <b-card-text>
                  <ul>
                    <li v-for="item of ingredients">
                      {{item.name}} {{item.quantity}}
                    </li>
                  </ul>
                </b-card-text>

              </b-card>

              <b-card class="w-auto">
                <b-card-title>Βασικά Στοιχεία</b-card-title>
                <b-card-text>
                  <table style="width:100%" class="table table-borderless">
                    <tr>
                      <td>Ενέργεια</td>
                      <td class="text-right">{{ details.kcal }} kcal</td>
                    </tr>
                    <tr>
                      <td>Υδατάνθρακες</td>
                      <td class="text-right">{{ details.carbs }} g</td>

                    </tr>
                    <tr>
                      <td>Πρωτεϊνες</td>
                      <td class="text-right">{{ details.proteins }} g</td>

                    </tr>
                    <tr>
                      <td>Λίπη</td>
                      <td class="text-right">{{ details.fats }} g</td>
                    </tr>
                  </table>
                </b-card-text>

              </b-card>
            </div>
          </div>

        </b-card>


      </b-tab>
      <b-tab title="Συζήτηση / σχόλια">
        <cloud-listing-comments :listing-id="listingId"></cloud-listing-comments>
      </b-tab>
    </b-tabs>

    <div class="card-body">

    </div>

  </div>
</template>


<script>
import CloudListingComments from "@components/cloud/preview/CloudListingComments";
export default{
  components:{CloudListingComments},
  props:{
    recipe: Object,
    listingId: String
  },
  computed:{
    ingredients(){

      return this.recipe.meal.foods.map(
        x => {

          return {
            name: x.name,
            quantity: x.quantity * x['mealFoods.quantity'] + 'gr'
          }
      }
    );
    }
  },
  data(){
    return{
      details: {
        carbs: 0,
        fats: 0,
        proteins: 0,
        kcal: 0
      }
    }
  },
  mounted(){
    this.getRecipeDetails()
  },

  methods:{
    getRecipeDetails(){
      for(let food of this.recipe.meal.foods){

        this.details.carbs += food.carbohydrates * food['mealFoods.quantity'];
        this.details.fats += food.fats * food['mealFoods.quantity'];
        this.details.proteins += food.proteins * food['mealFoods.quantity'];
        this.details.kcal += food.kcal * food['mealFoods.quantity'];
      }
      for(let key of Object.keys(this.details)){
        this.details[key] = +this.details[key].toFixed(2);
      }
    }
  }
}
</script>
