import { render, staticRenderFns } from "./_loading.vue?vue&type=template&id=4af87e9b&"
import script from "./_loading.vue?vue&type=script&lang=js&"
export * from "./_loading.vue?vue&type=script&lang=js&"
import style0 from "./_loading.vue?vue&type=style&index=0&id=4af87e9b&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_f3ovp5syquuyqzyjytk6i7vpxq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports