export default{

    'diet-plans': 'Διατροφικά Πλάνα',
    foods: 'Τρόφιμα',
    athletes: 'Πελάτες',
    notifications: 'Ενημερώσεις',
    calendar: 'Ημερολόγιο',
    meals: 'Πρότυπα Γεύματα',
    statistics: 'Στατιστικά',
    notes: 'Πρότυπα σημειώσεων',
    recipes: 'Συνταγές',
    company: 'Στοιχεία εταιρείας',
    'change-password': 'Αλλαγή Κωδικού Πρόσβασης',
    'todo-list': 'Λίστα to-do',
    tags: 'Ετικέτες',
    subscription: 'Συνδρομή & Ανανεώση',
    'print-templates': 'Πρότυπα Εκτύπωσης',
    questionnaires: 'Ερωτηματολόγια',
    user: 'Λογαριασμός',
    'pay-transaction': 'Πληρωμή',
    library: 'Επιστημονική Βιβλιοθήκη',
    faq: 'Συχνές Ερωτήσεις',
    terms: 'Όροι χρήσης',
    admin: 'Διαχείριση',
    users: 'Λίστα χρηστών',
    transactions: 'Συναλλαγές',
    reports: 'Αναφορές Χρηστών',
    add: 'Προσθήκη νέου πελάτη',
    temp: 'Προσχέδιο',
    coupons: 'Κουπόνια Προσφοράς / Συνεργατών'
}