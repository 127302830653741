<script>


export default {
  page: {
    title: 'Page timeout',
    meta: [
      { name: 'description', content: 'The page timed out while loading.' },
    ],
  },
  data(){
    return {
      prevRoute: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
}
</script>

<template>
  <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">

          <div class="card-body p-4">
            <div class="error-ghost text-center">
              <img src="@assets/images/issue.svg" class="img-elements-not-found" alt="" height="250"/>
            </div>


            <div class="text-center mt-4">
               <h3 class="mt-4 text-uppercase font-weight-bold">ΚΑΤΙ ΠΗΓΕ ΛΑΘΟΣ...</h3>
                  <p class="text-muted mb-3">
                    Κάτι πήγε λάθος είτε με την εφαρμογή, είτε με το Internet σας. Παρακαλώ δοκιμάστε ξανά σε λίγο!
                    <!--<a href class="text-dark">-->
                      <!--<b>Support</b>-->
                    <!--</a>-->
                  </p>
                </div>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
    </div>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
