export default{
    meals: {
        settings_title: 'Επεξεργασία σειράς/ονομασίας γευμάτων',
        options: 'Επιλογές γευμάτων διατροφικού πλάνου',
        add: 'Προσθήκη έξτρα γεύματος',
        remove: {
            btn: 'Αφαίρεση έξτρα γεύματος',
            modal_title: '(ΠΡΟΣΟΧΗ) Θέλετε σίγουρα να αφαιρέσετε το έξτρα γεύμα;',
            modal_body: 'ΠΡΟΣΟΧΗ! Θα διαγραφεί το μεγαλύτερο (τελευταίο) πρόσθετο γεύμα. Εάν έχετε προσθέσει 2 έξτρα γεύματα, θα διαγραφεί το 2ο.' +
                ' Τα τρόφιμα και οι σημειώσεις τους θα διαγραφούν επίσης. <strong>Η ενέργεια είναι οριστική και τα δεδομένα που έχουν διαγραφεί δεν μπορούν να ανακτηθούν.</strong>',
            delete_button: 'Διαγραφή γεύματος',
            cancel: 'Ακύρωση'
        },
        extra: 'Έξτρα γεύμα',
        0: 'Πρωϊνό',
        1: 'Δεκατιανό',
        2: 'Μεσημεριανό',
        3: 'Απογευματινό',
        4: 'Βραδινό',
        5: 'Έξτρα γεύμα 1',
        6: 'Έξτρα γεύμα 2'
    }
}