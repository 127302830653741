<script>
import {isSerialInt, isUUID} from "@utils/util";
import {mapState} from "vuex";

export default {
  components: {},
  data(){
    return{
      title: null,
    }
  },
  computed:{
    ...mapState({
      activeItems: state => state.ui.activeItems
    }),
    items(){

      let items = [{text: this.$t('home'), to: '/'}];
      let path = this.$route.path.split('/').filter(x => x !== '');

      let dynamicDataIndex = 0;
      for(let i=0;i<path.length;i++){
        // last item

        let is_non_translatable_text = false;

        let text = path[i];

        // special case for athletes diet plans

        if(text === 'diet-plan' && items.find(x => x.to === '/athletes')){

          items.push({
            text: this.$t('routes.diet-plans'),
            to: '/athletes/'+ this.$route.params.athleteId + '/#dietplans'
          });
          continue;
        }


        if(isUUID(text) || isSerialInt(text)){
          try{
            is_non_translatable_text = true;
            text = this.activeItems[dynamicDataIndex];
            dynamicDataIndex++;
          }catch(e){
          }

        }else{
          text = this.$t('routes.'+text);
        }

        if(i===path.length-1){
          items.push({
            text: text,
            active: true}
          );
          this.title =  text;
        }else{
          let pathUntilNow = path.slice(0, i+1).join('/');

          items.push({text: text, to: '/'+ pathUntilNow})
        }
      }
      return items;
    }
  }
}
</script>

<template>
  <!-- start page title -->
  <div v-show="!$route.meta.hideBreadcrumbs" class="row">
    <div  class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">

          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
        <h4 class="page-title">
          {{ $route.name === 'athlete-diet-plan' && this.activeItems.length > 0 ? this.activeItems[0] :  title }}</h4>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
