import Vue from 'vue'
import VueI18n from "vue-i18n"

import el from '@/src/lang/el/index'

const messages = {
    el
}

Vue.use(VueI18n)

// VueI18n instance
export default new VueI18n({
    locale: 'el',
    messages
})
