import axios from "axios"
import {getSavedState, saveState} from "@utils/util";
export const state = {

    selectedUser: null,
    selectedUserInfo: {},
    usersList: [],
    lastViewed: getSavedState('chat.lastViewed'),
    openedChat: null,
    unreadChats: 0,
    recentChats: [],
    timeout: null

};

export const getters = {

};


export const mutations = {

    SET_SELECTED_USER(state, val){
        state.selectedUser = val;
    },
    SET_SELECTED_USER_INFO(state, val){
        state.selectedUserInfo = val;
    },
    SET_LAST_VIEWED(state, val){
        state.lastViewed = val;
        saveState('chat.lastViewed', val);
    },
    SET_RECENT_CHATS(state, val){
        state.recentChats = val;
    },
    SET_UNREAD_COUNT(state, val){
        state.unreadChats = val;
    }

};

export const actions = {

    async getRecentChats({commit}){

        const recentChats = []
        axios.get(process.env.VUE_APP_MOBILE_API + "/chat/recent-chats").then(res =>{
            const userIds =  res.data.recentChats.map(x => x.userId);
            for(let recent of res.data.recentChats){
                recentChats.push({user: recent.user, userId: recent.userId, text: recent.lastMessage,
                    isUnread: parseInt(recent.lastMessageId) > parseInt(recent.lastReadMessageId) });
            }
            commit('SET_RECENT_CHATS', recentChats);
            commit('SET_UNREAD_COUNT', res.data.unreadCount);

        }).catch(e=>{
            return e;
        })
    },

    setLastViewed({commit}, val){
        commit('SET_LAST_VIEWED', val);
    },

    setSelectedUser({commit}, val){

        const recentChatsEntry = state.recentChats.find(x => x.userId === val)
        commit('SET_SELECTED_USER', val);

        if(!recentChatsEntry) return;
        if(recentChatsEntry.isUnread){
            recentChatsEntry.isUnread = false;
            commit('SET_UNREAD_COUNT', state.unreadChats - 1)
        }
    },

    setSelectedUserInfo({commit}, val){
        commit('SET_SELECTED_USER_INFO', val);
    },

    reset({state, commit}){
        commit('SET_RECENT_CHATS', null);
        commit('SET_UNREAD_COUNT', 0);
        commit('SET_LAST_VIEWED', null);
        commit('SET_SELECTED_USER', null);
        commit('SET_SELECTED_USER_INFO', null);

    }

}
