<template>
  <div id="daily-fiber">
    <h5>Χρόνια Νεφρική Ανεπάρκεια - GFR Cockcroft-Gault</h5>
    <span class="text-muted">Υπολογισμός με την εξίσωση GFR Cockcroft-Gault.</span>

    <div class="row mt-4">
      <div class="col-md-12">
        <b-form-group label="Φύλο:" label-for="edit-name">
          <b-form-select v-model="gender">
            <option value="M">Άρρεν</option>
            <option value="F">Θήλυ</option>
          </b-form-select>
        </b-form-group>

        <b-form-group label="Ηλικία" label-for="edit-age">
          <b-input-group append="έτη" >
            <b-form-input id="edit-age" v-model="age" type="text" placeholder="Ηλικία"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Σωματικό βάρος" label-for="edit-g">
          <b-input-group append="kg" >
            <b-form-input id="edit-g" v-model="kg" type="text" placeholder="Σωματικό βάρος (kg)"
                          @input="kg = replaceCommaWithDot(kg)"></b-form-input>
          </b-input-group>
        </b-form-group>


        <b-form-group label="Κρεατινίνη Ορού" label-for="edit-oro">
          <b-input-group append="mg/dl" >
            <b-form-input id="edit-oro" v-model="oros" type="text" placeholder="Κρεατινίνη Ορού"
                          @input="oros = replaceCommaWithDot(oros)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-12 text-right">
        <b-button variant='primary' @click="calculate">Υπολογισμός</b-button>
      </div>
      <div v-if="result" class="col-md-12 text-center mt-3">
        <h4><strong>{{ result }}</strong> </h4>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      gender: 'M',
      age: null,
      kcal: null,
      result: null,
      kg: null,
      oros: null
    }
  },
  methods:{
    calculate(){
      let resultTable = ['Στάδιο 1 με φυσιολογικό ή υψηλό GFR', 'Στάδιο 2 Ήπια ΧΝΑ', 'Στάδιο 3Α Μέτρια ΧΝΑ', 'Στάδιο 3Β Μέτρια ΧΝΑ', 'Στάδιο 4 Σοβαρή ΧΝΑ',
        'Στάδιο 5 Τελικό Στάδιο ΧΝΑ']
      let result = ((140-this.age) * this.kg) / (72*this.oros) * (this.gender === 'M' ? 1 : 0.85);
      if(result > 90){
        this.result = resultTable[0];
      }else if(result >= 60){
        this.result = resultTable[1];
      }else if(result >= 45){
        this.result = resultTable[2];
      }else if(result >= 30){
        this.result = resultTable[3];
      }else if(result >=15){
        this.result = resultTable[4];
      }else{
        this.result = resultTable[5];
      }
    }
  }
}

</script>
